import React from 'react';
import { Route } from 'react-router-dom';

const chilidrenRoute = ({ children }) => {
  if (!children) {
    return null
  }
  return children.map((value) => {
    return (
      <Route path={value.path} element={value.element} key={value.name}>
        {chilidrenRoute(value)}
      </Route>
    )
  })
}
export default chilidrenRoute;