import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";

const productIconList = [
  {
    name: "产供销协同平台",
    url: require("../../assets/supply_icon.png"),
    description: "构建智能供应网络，显著提升库存的周转率和服务水平",
    path: "produceSupply",
  },
  {
    name: "多渠道价盘管理平台",
    url: require("../../assets/price_icon.png"),
    description: "精准的价格管理体系，实现价格看得见、管得住、用得好",
    path: "multiplePrice",
  },
  {
    name: "数字化经营灯塔",
    url: require("../../assets/lighthouse_icon.png"),
    description: "不止于企业信息化工具更是智慧经营大脑",
    path: "lighthouse",
  },
  {
    name: "合作伙伴协同平台",
    url: require("../../assets/partner_icon.png"),
    description: "不仅是采购协同，而是对外部合作伙伴的整体协同门户",
    path: "partner",
  },
];
const solutionIconList = [
  {
    name: "数字化供应链解决方案",
    url: require("../../assets/supply_chain_icon.png"),
    description: "供应链数字化转型，构建智能供应链体系",
    path: "supplyChain",
  },
];
const ProductMenu = (props) => {
  const { changeNav } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLeft, setActiveLeft] = useState(0);
  const [hoverRight, setHoverRight] = useState(null);
  const [activeRight, setActiveRight] = useState("");
  const [menuList, setMenuList] = useState(productIconList);

  const changeLeft = (e) => {
    setActiveLeft(e);
    setMenuList(e === 0 ? productIconList : solutionIconList);
  };

  useEffect(() => {
    let key = location.pathname.split("/")[1];
    setActiveLeft(["supplyChain"].includes(key) ? 1 : 0);
    setMenuList(
      ["supplyChain"].includes(key) ? solutionIconList : productIconList
    );
    setActiveRight(key);
  }, []);

  const changeRight = (item) => {
    window.scrollTo(0, 0);
    navigate(item.path);
    changeNav(item.path);
  };

  return (
    <div
      className="product-container flex-jcb"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="left-menu font-16">
        <div
          className={
            activeLeft === 0 ? "active-left-item left-item" : "left-item"
          }
          onClick={() => changeLeft(0)}
        >
          产品线
        </div>
        <div
          className={
            activeLeft === 1 ? "active-left-item left-item" : "left-item"
          }
          onClick={() => changeLeft(1)}
        >
          解决方案
        </div>
      </div>
      <div className="right-menu">
        {menuList.map((item, index) => {
          return (
            <div
              className={`right-item flex-alc ${
                hoverRight === index || activeRight === item.path
                  ? "item-hover"
                  : ""
              }`}
              key={index}
              onMouseEnter={() => setHoverRight(index)}
              onMouseLeave={() => setHoverRight(null)}
              onClick={() => changeRight(item)}
            >
              <img src={item.url} alt="" className="menu-icon" />
              <div>
                <div className="right-item-name">{item.name}</div>
                <div className="right-item-description">{item.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ProductMenu;
