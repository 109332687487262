import PageHeadImage from "../../components/pageHeadImage";
import BlockComponent from "../../components/blockComponent";
import ModuleHead from "../../components/moduleHead";
import { CaretDownOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";

const pointList = [
  {
    icon: require("../../assets/shortage.png"),
    title: "畅销品经常断货",
    description: "无法满足⽤户需求，⽩⽩损失销售机会，⽤户体验也大打折扣。",
  },
  {
    icon: require("../../assets/unsalable.png"),
    title: "部分商品滞销",
    description: "部分商品备货过多，经常出现滞销情况，且占⽤⼤量库存空间。",
  },
  {
    icon: require("../../assets/no_rule.png"),
    title: "库存管理无规则",
    description: "商品库存管理缺乏科学规则，库存周转慢，库存持有成本⾼。",
  },
  {
    icon: require("../../assets/wastage.png"),
    title: "短保商品损耗大",
    description: "⽣鲜等短保商品，库存损耗居⾼不下，净利润受到很大冲击。",
  },
];

const beforeList = [
  {
    text: "补货时间点靠经验和拍脑袋，经常出现缺货才知道要补货，形成⼀段时间的⽆货期。",
  },
  { text: "补货数量拿捏不定，要么是补多滞销，要么补少缺货。" },
  { text: "商品品类较多，⼈⼯每⽇检查⼯作量⼤，⽽且容易造成疏漏。" },
  {
    text: "⼈⼯⽆法时刻关注到异常，往往等缺货或者库存滞销堆积后才知道，但事后补救为时已晚。",
  },
];

const afterList = [
  {
    text: "系统综合考虑在途、已下单未在途库存、采购提前期、箱规等各⽅⾯因素计算准确的补货量，每天滚动计算。",
  },
  { text: "结合预测数据，给到各个商品当前可销天数和补货后未来可销天数。" },
  {
    text: "补货建议直接与⻔店端及采购端打通，员⼯可根据建议量⼀键下单或调整数量。",
  },
  {
    text: "系统时刻进⾏异常监控，当即将出现缺货、滞销等情况时及时通知相关⼈员介⼊处理。",
  },
];
const advancedList = [
  {
    title: "库存周转率",
    trend: "提升40%",
    img: "rise",
  },
  {
    title: "库存报废率",
    trend: "降低75%",
    img: "drop",
  },
  {
    title: "门店履约周期",
    trend: "提升50%",
    img: "rise",
  },
  {
    title: "采购需求满足率",
    trend: "提升30%",
    img: "rise",
  },
];
// const valueList = [
//   {
//     goal: "库存周转率",
//     value: "库存⾦额占⽤，资⾦利⽤率",
//     range: "预计周转天数降低20%",
//     profitTitle: "可提升利润额4000万元/年",
//     profitDesc:
//       "计算逻辑：根据假设企业库存原持有⾦额为240/(360/30) =20亿元；库存周转天数周转天数降低20%，则降低为24天：库存持有⾦额降低=20*(1- 24/30)=4亿元，按投资回报率10%计算，可提升利润额4000万元/年。",
//   },
//   {
//     goal: "缺货率",
//     value: "机会成本、销售额",
//     range: "预计降低缺货率20%",
//     profitTitle: "可提升销售额1.44亿元/年",
//     profitDesc:
//       "计算逻辑：缺货率导致的机会成本（销售额）的减损，⻔店缺货率按照10%计算，前提假设企业年销售额240亿元；库存周转时间为30天；货率按照10%计算，降低20%则为8%，2%的提升⾥按30%转化为实际销售，则销售额预计可以提升1.7亿元。",
//   },
//   {
//     goal: "⼈效",
//     value: "⼈⼒成本",
//     range: "预计降低10%",
//     profitTitle: "降低⼈⼒成本约2400万元",
//     profitDesc:
//       "计算逻辑：⼈⼒成本按销售额1%计算（⾏业通⽤），⼈⼒成本为2.4亿元，降低10%⼈⼒成本则为2400万元",
//   },
//   {
//     goal: "系统可⽤性",
//     value: "客户(内部)和⽤户(消费者)满意度",
//     range: "--",
//     profitTitle:
//       "提升决策数据的准确性，降低系统延迟，降低因故障导致的系统不可⽤的⼏率。",
//   },
// ];
const ProduceSupply = (props) => {
  return (
    <div className={styles["produce-supply"]}>
      <PageHeadImage
        title="产供销协同系统"
        content="构建智能供应网络，显著提升库存的周转率和服务水平"
        imgName="produce_supply_banner"
      />
      <BlockComponent
        backgroundClass={`white-bg ${styles["produce-content"]}`}
        pbottom="40px"
        title="客户痛点"
        detail={`不知道何时该补货以及⼀次该补多少，全靠拍脑袋凭经验，导致库存管理存在诸多问题。`}
      />
      <div className="plr-10 white-bg" style={{ paddingBottom: "100px" }}>
        <div className="main-area flex-jcb">
          {pointList.map((item, index) => {
            return (
              <div key={index} className={styles["point-card"]}>
                <img src={item.icon} alt="" className={styles["card-icon"]} />
                <div className={styles["card-title"]}>{item.title}</div>
                <div className={styles["card-desc"]}>{item.description}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="plr-10 white-bg">
        <div className="main-area">
          <ModuleHead title="解决方案" line={false} />
          <div className="flex-alc-jcs mt-30">
            <div className={styles["solution-title"]}>Before</div>
            <div
              className={styles["solution-title"]}
              style={{ color: "#0019FF" }}
            >
              After
            </div>
          </div>
          <div className="flex-alc-jcs pb-100">
            <div
              className={`${styles["transfer-box"]} ${styles["before-box"]}`}
            >
              {beforeList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles["transfer-item"]} ${styles["before-item"]}`}
                  >
                    <CaretDownOutlined
                      style={{
                        color: "rgba(119, 123, 147, 0.8)",
                        fontSize: "20px",
                      }}
                    />
                    <div className={styles["tranfer-text"]}>{item.text}</div>
                  </div>
                );
              })}
            </div>
            <img
              src={require("../../assets/transfer.png")}
              alt=""
              className={styles["transfer-img"]}
            />
            <div className={`${styles["transfer-box"]} ${styles["after-box"]}`}>
              {afterList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles["transfer-item"]} ${styles["after-item"]}`}
                  >
                    <CaretDownOutlined
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    />
                    <div className={styles["tranfer-text"]}>{item.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="relative" style={{ height: "578px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className={styles["step-number"]}>01</div>
          </div>
        </div>
        <div className={styles["fix-block"]}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="produce1"
            pbottom="90px"
            subtitle="完善的供应链计划⽅法论"
            detail={`基于⾃主知识产权的供应链计划⽅法论体系，结合机器学习、运筹优化等先进的技术⼿段，考虑到节假⽇、环境、商品、销售等内外部多维数据，极⼤的提升预测预测补货效果，为库存⽔位控制提供精确、⾼效的执⾏建议，防⽌缺货与滞销并存的问题。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "430px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className={`${styles["step-number"]} flex-jce`}>02</div>
          </div>
        </div>
        <div className={styles["fix-block"]} style={{ top: "85px" }}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            Reverse={true}
            imageName="produce2"
            pbottom="91px"
            subtitle="供应链三道防线"
            detail={`构筑供应链构建三道防线的思想，通过层层防御，最⼤限度避免滞销和断货的情况发⽣。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "494px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className={styles["step-number"]}>03</div>
          </div>
        </div>
        <div className={styles["fix-block"]} style={{ top: "90px" }}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="produce3"
            pbottom="90px"
            subtitle="库存健康监测"
            detail={`能够实时监控库存状态，并通过不同颜⾊提示到当前库存的健康状态，同时给出紧急补货或者催预约⼊库等建议。⽤户可以⼀键下补货订单。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "526px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className={`${styles["step-number"]} flex-jce`}>04</div>
          </div>
        </div>
        <div className={styles["fix-block"]} style={{ top: "90px" }}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            Reverse={true}
            imageName="produce4"
            pbottom="90px"
            subtitle="⾃动化预警系统"
            detail={`预警机制可以根据需要为重点仓/⻔店的重点商品 配置预警规则，当达到预警阈值的时候会推送预警给到指定⽤户。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "404px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className={styles["step-number"]}>05</div>
          </div>
        </div>
        <div className={styles["fix-block"]} style={{ top: "90px" }}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="produce5"
            subtitle="采购协同"
            detail={`含采购计划协同、采购订单协同、送货协同，⼀站式解决⽇常采购执⾏⼯作。通过协同实现采购体系内各⻆⾊、多维度信息深度互通，通过协同平台进⾏信息交换，最⼤程度降低供应链采购各环节信息不对称。`}
          />
        </div>
      </div>
      <div className="white-bg" style={{ paddingBottom: "117px" }}>
        <div className={`plr-10 ${styles["produce-bg"]}`}>
          <div className="main-area">
            <ModuleHead title="应用价值" line={false} />
            <div className={`font-14 mt-40 ${styles["value-text"]}`}>
              前提假设：企业年销售额240亿元；库存周转时间为30天
            </div>
            <div className={styles["grid-style"]} style={{ marginTop: "6px" }}>
              <div className="flex-alc-jcs mb-10">
                <div
                  className={`fwb ${styles["w-170"]} ${styles["grid-title"]}`}
                >
                  现状
                </div>
                <div
                  className={`fwb ${styles["w-634"]} ${styles["grid-title"]}`}
                >
                  改进
                </div>
                <div
                  className={`fwb ${styles["w-184"]} ${styles["grid-title"]}`}
                >
                  效果
                </div>
              </div>

              <div className="flex-alc-jcs mb-10">
                <div className={`font-20 ${styles["w-170"]} fwb`}>
                  <div className={`${styles["grid-value"]} mb-8`}>滞销</div>
                  <div className={`${styles["grid-value"]} mb-8`}>缺货</div>
                  <div className={`${styles["grid-value"]} mb-8`}>
                    库存 <br />
                    报废
                  </div>
                  <div className={styles["grid-value"]}>
                    价格 <br />
                    混乱
                  </div>
                </div>
                <div
                  className={`font-20 flex-alc-jcs ${styles["w-634"]} ${styles["middle-card"]}`}
                >
                  <div className="ml-32">
                    <div className={styles["middle-text"]}>
                      智能计划、智能补货
                    </div>
                    <div className={styles["middle-text"]}>货盘管理</div>
                    <div className={styles["middle-text"]}>
                      全渠道价盘管控体系
                    </div>
                    <div className={styles["middle-text"]}>数字化经营灯塔</div>
                  </div>
                  <div className={`flex-column`}>
                    <img
                      src={require("../../assets/process-arrow2.png")}
                      alt=""
                      className={`${styles.arrow_style} ${styles.mb118}`}
                    />
                    <img
                      src={require("../../assets/process-arrow2.png")}
                      alt=""
                      className={`${styles.arrow_style} ${styles.mb118}`}
                    />
                    <img
                      src={require("../../assets/process-arrow2.png")}
                      alt=""
                      className={styles.arrow_style}
                    />
                  </div>
                  <div className={`flex-column mr-28 fwb`}>
                    {advancedList.map((item, index) => {
                      return (
                        <div
                          className={`${styles.advanced_item} flex-column flex-jcc`}
                          key={index}
                        >
                          <div>{item.title}</div>
                          <div className="flex-alc">
                            <div>{item.trend}</div>
                            <img
                              src={require(`../../assets/${item.img}2.png`)}
                              alt=""
                              className={`${styles.advanced_img} ml-8`}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`${styles["w-184"]} ${styles["middle-card"]} flex-column flex-jcc`}
                >
                  <div
                    className={`ml-32 font-24 fwb ${styles.blue_text} ${styles.mb60}`}
                  >
                    成本降低 <br /> 20%+
                  </div>
                  <div className={`ml-32 font-24 fwb ${styles.blue_text}`}>
                    毛利提升 <br /> 1%+
                  </div>
                </div>
              </div>

              {/* {valueList.map((item, index) => {
                return (
                  <div className="flex-alc-jcs mb-10" key={index}>
                    <div
                      className={`font-20 ${styles["w-170"]} ${styles["grid-value"]}`}
                    >
                      {item.goal}
                    </div>
                    <div
                      className={`${styles["w-184"]} ${styles["grid-value"]} ${styles["middle-text"]}`}
                    >
                      {item.value}
                    </div>
                    <div
                      className={`${styles["w-184"]} ${styles["grid-value"]} ${styles["middle-text"]}`}
                    >
                      {item.range}
                    </div>
                    <div
                      className={`${styles["w-634"]} ${styles["grid-value"]}`}
                    >
                      <div className={styles["profit-title"]}>
                        {item.profitTitle}
                      </div>
                      {item.profitDesc && (
                        <div className={styles["profit-desc"]}>
                          {item.profitDesc}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProduceSupply;
