import './App.css';
import React from "react";
import routes from './router/index.js';
import chilidrenRoute from './router/routeWithSubRoutes';
import { Routes, Route } from 'react-router-dom';


const App = () => {
  return (
    <div className="App">
      <Routes>
        {routes.map((item) => {
          return (
            <Route path={item.path} key={item.name} element={item.element}>
              {chilidrenRoute(item)}
            </Route>
          )
        })}
      </Routes>
    </div>
  );
};

export default App;