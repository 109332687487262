import React from "react";
import "./index.css";

const PageHeadImage = (props) => {
  const { title, subtitle, content, imgName } = props;
  return (
    <div className="product-head">
      <img
        src={require(`../../assets/${imgName}.png`)}
        alt=""
        className="top-img"
      />
      <div className="main-area">
        <div className="info">
          <div className="title fw-700 font-42">{title}</div>
          {subtitle && <div className="fw-500 font-20">{subtitle}</div>}
          <div className="detail font-20">{content}</div>
        </div>
      </div>
    </div>
  );
};
export default PageHeadImage;
