import ReactLayout from '../views/layOut';
import WebIndex from '../views/webIndex';
import Product from '../views/product';
import NewsList from '../views/news/list';
import NewsDetail from '../views/news/detail';
import AboutUs from '../views/aboutUs';
import ProduceSupply from '../views/produceSupply';
import MultiplePrice from '../views/multiplePrice';
import Lighthouse from '../views/lighthouse';
import Partner from '../views/partner';
import SupplyChain from '../views/supplyChain';

import { Navigate } from 'react-router-dom';

const routes = [
  {
    name: 'IndexUrl',
    path:'/',
    element: <Navigate to='/index' />,
  },
  {
    name: 'Layout',
    path: '/',
    element: <ReactLayout />,
    children: [
      {
        name: 'Index',
        path:'index',
        element: <WebIndex />,
      },
      {
        name: 'Product',
        path:'product',
        element: <Product />,
      },
      {
        name: 'NewsList',
        path:'newsList',
        element: <NewsList />,
      },
      {
        name: 'NewsDetail',
        path:'newsDetail',
        element: <NewsDetail />,
      },
      {
        name: 'AboutUs',
        path:'aboutUs',
        element: <AboutUs />,
      },
      {
        name: 'ProduceSupply',
        path:'produceSupply',
        element: <ProduceSupply />,
      },
      {
        name: 'MultiplePrice',
        path:'multiplePrice',
        element: <MultiplePrice />,
      },
      {
        name: 'Lighthouse',
        path:'lighthouse',
        element: <Lighthouse />,
      },
      {
        name: 'Partner',
        path:'partner',
        element: <Partner />,
      },
      {
        name: 'SupplyChain',
        path:'supplyChain',
        element: <SupplyChain />,
      },
    ]
  },
  {
    name: 'root',
    path: '*',
    element: <Navigate to='/' replace />
  },
];
export default routes;