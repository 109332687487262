import styles from "./styles.module.css";
import PageHeadImage from "../../components/pageHeadImage";
import BlockComponent from "../../components/blockComponent";
import ModuleHead from "../../components/moduleHead";
import { CaretDownOutlined } from "@ant-design/icons";

const pointList = [
  {
    icon: require("../../assets/head.png"),
    title: "靠拍脑袋决策",
    description: (
      <div>
        · 决策依据不足
        <br />· 强依赖经验主义
      </div>
    ),
  },
  {
    icon: require("../../assets/storage.png"),
    title: "要不到货和库存冗余并存",
    description: (
      <div>
        · 经销层级多· 销售渠道多
        <br />· 仓库网点多
      </div>
    ),
  },
  {
    icon: require("../../assets/uncontrol.png"),
    title: "价盘失控",
    description: (
      <div>
        · 渠道越来越多，价格越来越混乱
        <br />· 定价不科学，无法保持合理的毛利
      </div>
    ),
  },
  {
    icon: require("../../assets/unclear.png"),
    title: "看不清、看不全",
    description: (
      <div>
        · 数据展示不及时
        <br />· 数据展示不全，缺少管理成本和运营成本的展示
      </div>
    ),
  },
];

const stepList = [
  {
    number: "01.",
    title: "构建新一代数字化中台，实现数字化经营转型",
    img: require("../../assets/supply_chain3.png"),
    desc: "以用户体验为“中心”，从外部需求“倒逼”内部变革，企业发展的价值观和战略导向要从过去产能驱动型转变为数据驱动。以推动核心业务的数字化转型为目标，打通“横向、纵向和端到端”三大数据流。实现从从客户到生产、从前端到后端的“数据互联互通”能力。",
  },
  // {
  //   number: "02.",
  //   title: "通过数据进行供应链全链路数字化转型",
  //   img: require("../../assets/supply_chain4.png"),
  //   desc: "通过数据重构业务逻辑和物理属性的全局/全链路优化，提升效率&降低库存；整个链路的库存确定性越高，配送的时效越快和稳定性越高，商品效期控制越好商业结果就越好。",
  // },
  // {
  //   number: "03.",
  //   title: "构建一套基于数据体系的智能数字化经营体系",
  //   img: require("../../assets/supply_chain5.png"),
  // },
];
const productValueList = [
  {
    title:
      "不做片段价值输出者，做整体价值输出者——全链路数据驱动整体提升企业价值",
    img: require("../../assets/supply_chain6.png"),
  },
  {
    title: "以用户体验为中心，数据驱动为原则，与客户共创实现数字化经营转型",
    img: require("../../assets/supply_chain7.png"),
  },
  {
    title: "新一代供应链中台更关注业务经营价值",
    img: require("../../assets/supply_chain8.png"),
  },
];
const pathList = [
  { img: require("../../assets/path1.png"), title: "共创蓝图" },
  { img: require("../../assets/path2.png"), title: "业务数据化" },
  { img: require("../../assets/path3.png"), title: "数据业务化" },
  { img: require("../../assets/path4.png"), title: "持续迭代" },
];
const processList = [
  "业务/IT梳理",
  "TOBE设计共识",
  "基础平台上线收集数据",
  "数字化调优适配",
  "验收推广运维",
  "系统转型应对供需变化挑战",
];
const SupplyChain = (props) => {
  return (
    <div className={styles.supply_chain}>
      <PageHeadImage
        title="价值供应链中台"
        content="全链路数字化转型，构建智能数字化经营体系"
        imgName="produce_supply_banner"
      />
      <div className="plr-10 white-bg" style={{ paddingTop: "90px" }}>
        <div className="main-area">
          <ModuleHead title="产品背景" line={false} />
          <div className="flex-alc-jcs mt-40">
            <img
              src={require("../../assets/supply_chain1.png")}
              alt=""
              className={styles.background_img}
            />
            <div className={styles.background_text}>
              <div className="mb-30">
                <div className="flex-alc">
                  <img
                    src={require("../../assets/triangle-right.png")}
                    className={styles.background_icon}
                  />
                  <div className={styles.background_title}>
                    “增量竞争”到“存量竞争”
                  </div>
                </div>
                <div className={styles.background_desc}>
                  目前中国进入物质相对丰富、市场相对饱和阶段，市场零售总额增速明显放缓，仅仅依靠“跑马圈地”无法保障企业生存
                </div>
              </div>
              <div>
                <div className="flex-alc">
                  <img
                    src={require("../../assets/triangle-right.png")}
                    className={styles.background_icon}
                  />
                  <div className={styles.background_title}>
                    供应链不稳定性长期持续
                  </div>
                </div>
                <div className={styles.background_desc}>
                  地缘政治、贸易摩擦等政治经济因素冲击供应链稳定性，导致整体行业库存周转天数大幅增长
                </div>
              </div>
            </div>
          </div>
          <div className={styles.tips}>
            （数据来源：中国连锁经营协会（CCFA）供应链研究报告）
          </div>
          <div className="flex-jcb mt-40" style={{ paddingBottom: "70px" }}>
            {pointList.map((item, index) => {
              return (
                <div key={index} className={styles.pain_card}>
                  <img src={item.icon} alt="" className={styles.card_icon} />
                  <div className={styles.card_title}>{item.title}</div>
                  <div className={styles.card_desc}>{item.description}</div>
                </div>
              );
            })}
          </div>
          <div className="relative" style={{ zIndex: 2 }}>
            <div
              className="flex-alc-jcs"
              style={{ zIndex: 2, marginBottom: "100px" }}
            >
              <img
                src={require("../../assets/quotation1.png")}
                alt=""
                className={styles.quotation}
              />
              <div className={styles.quotation_slogen}>
                企业供应链建设，要从粗放的基于经验主义传统经营思路
                <br />
                转型到高度精细化数字化经营
              </div>
              <img
                src={require("../../assets/quotation2.png")}
                alt=""
                className={styles.quotation}
              />
            </div>
            <ModuleHead title="核心挑战" line={false} />
            <img
              src={require("../../assets/supply_chain2.png")}
              alt=""
              style={{ width: "100%" }}
              className="mt-40"
            />
          </div>
        </div>
        <img
          src={require("../../assets/produce-bg.png")}
          alt=""
          className={styles.core_bg}
        />
      </div>
      <div className="plr-10 white-bg relative" style={{ zIndex: 2 }}>
        <div className="main-area pt-100">
          <ModuleHead title="应对措施" line={false} />
          {stepList.map((item, index) => {
            return (
              <div className="mt-20 relative pb-20" key={index}>
                {/* <div className={styles.step_number}>{item.number}</div> */}
                <div className={styles.step_title}>{item.title}</div>
                <img
                  src={item.img}
                  alt=""
                  className={styles.step_img}
                  style={{ marginTop: index === 2 ? "20px" : "6px" }}
                />
                {item.desc && (
                  <div className={styles.step_desc}>{item.desc}</div>
                )}
              </div>
            );
          })}
          {/* <div className="pt-120">
            <ModuleHead title="产品理念" line={false} />
            {productValueList.map((item, index) => {
              return (
                <div className={index === 2 ? "" : "pb-100"} key={index}>
                  <div
                    className="flex-alc"
                    style={{ marginBottom: index === 0 ? 0 : "20px" }}
                  >
                    <div className={styles.short_bar}></div>
                    <div className={styles.product_value_title}>
                      {item.title}
                    </div>
                  </div>
                  <img src={item.img} alt="" style={{ width: "100%" }} />
                </div>
              );
            })}
            <div className="mt-20 font-16 lh-30">
              <span className="fwb c-title">· 智能化业务决策：</span>
              <span className="c-light">
                在关键节点充分借助信息技术与数据资产，帮助业务进行智能决策；
              </span>
            </div>
            <div className="font-16 lh-30">
              <span className="fwb c-title">· 一体化运营管：</span>
              <span className="c-light">
                供应链一体化集成管理，实现高效运作、统一指挥、统一行动；
              </span>
            </div>
            <div className="font-16 lh-30">
              <span className="fwb c-title">· 生态化价值合作：</span>
              <span className="c-light">
                拉通企业生态内各个主体实现合作共赢，推动生态共同体可持续健康发展；
              </span>
            </div>
            <div className="font-16 lh-30">
              <span className="fwb c-title">· 敏捷化变革创新：</span>
              <span className="c-light">
                面对市场环境变化或者竞争态势的转变，支撑企业快速、敏捷地进行变革和创新；
              </span>
            </div>
            <div className="font-16 lh-30">
              <span className="fwb c-title">· 面向未来：</span>
              <span className="c-light">
                在经营活动实际发生之前预见性地进行预警和预测，帮助企业经营者提前识别问题并进行解决，避免实际损失发生之后才后知后觉
              </span>
            </div>
          </div> */}
        </div>
      </div>
      <div className="plr-10 white-bg relative">
        <div className="main-area pt-100 relative pb-120" style={{ zIndex: 2 }}>
          <ModuleHead title="应用价值" line={false} />
          <div className={styles.grid_style}>
            <div className="flex-alc-jcs mb-10">
              <div className={`${styles.grid_title} ${styles.w195} fwb`}>
                现状
              </div>
              <div className={`${styles.grid_title} ${styles.w594} fwb`}>
                改进
              </div>
              <div className={`${styles.grid_title} ${styles.w195} fwb`}>
                效果
              </div>
            </div>
            <div>
              <div className={`flex-alc-jcs`}>
                <div>
                  <div
                    className={`font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w195}`}
                  >
                    滞销
                  </div>
                  <div
                    className={`font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w195}`}
                  >
                    缺货
                  </div>
                </div>
                <div>
                  <div
                    className={`font-20 ${styles.grid_value} ${styles.goal_title} ${styles.w292} ${styles["middle-text"]}`}
                  >
                    智能计划、智能补货
                  </div>
                  <div
                    className={`font-20 ${styles.grid_value} ${styles.goal_title} ${styles.w292} ${styles["middle-text"]}`}
                  >
                    货盘管理
                  </div>
                </div>
                <div>
                  <div
                    className={`flex-alc font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w292} ${styles.grid_icon_style}`}
                  >
                    <span>库存周转率提升40%</span>
                    <img
                      src={require("../../assets/rise.png")}
                      alt=""
                      className={styles.float_icon}
                    />
                  </div>
                  <div
                    className={`flex-alc font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w292} ${styles.grid_icon_style}`}
                  >
                    <span>库存报废率降低75%</span>
                    <img
                      src={require("../../assets/drop.png")}
                      alt=""
                      className={styles.float_icon}
                    />
                  </div>
                </div>
                <div
                  style={{ height: "170px" }}
                  className={`font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w195}`}
                >
                  成本降低
                  <br />
                  20%
                </div>
              </div>
              <div className={`flex-alc-jcs`}>
                <div>
                  <div
                    className={`font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w195}`}
                  >
                    库存报废
                  </div>
                  <div
                    className={`font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w195}`}
                    style={{ height: "120px" }}
                  >
                    价格混乱
                  </div>
                </div>
                <div>
                  <div
                    className={`font-20 ${styles.grid_value} ${styles.goal_title} ${styles.w292} ${styles["middle-text"]}`}
                  >
                    全渠道价盘管控体系
                  </div>
                  <div
                    className={`font-20 ${styles.grid_value} ${styles.goal_title} ${styles.w292} ${styles["middle-text"]}`}
                    style={{ height: "120px" }}
                  >
                    数字化控制台，可视化+诊断+预警
                  </div>
                </div>
                <div>
                  <div
                    className={`flex-alc font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w292}  ${styles.grid_icon_style}`}
                  >
                    <span>门店履约周期提升50%</span>
                    <img
                      src={require("../../assets/rise.png")}
                      alt=""
                      className={styles.float_icon}
                    />
                  </div>
                  <div
                    className={`flex-alc font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w292}  ${styles.grid_icon_style}`}
                    style={{ height: "120px" }}
                  >
                    <span>采购需求满足率提升30%</span>
                    <img
                      src={require("../../assets/rise.png")}
                      alt=""
                      className={styles.float_icon}
                    />
                  </div>
                </div>
                <div
                  style={{ height: "210px" }}
                  className={`font-20 fwb ${styles.grid_value} ${styles.goal_title} ${styles.w195}`}
                >
                  毛利提升
                  <br />
                  1%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-area relative" style={{ zIndex: 2 }}>
          <ModuleHead title="产品功能矩阵" line={false} />
          {/* <img
            src={require("../../assets/supply_chain9.png")}
            alt=""
            className="w-100 mt-60"
          /> */}
          <img
            src={require("../../assets/supply_chain10.png")}
            alt=""
            className="w-100 mt-60 mb-120"
          />
          <ModuleHead title="数字化转型实施路径" line={false} />
          <div className="flex-jcb mt-40" style={{ padding: "0 17px" }}>
            {pathList.map((item, index) => {
              return (
                <div key={index} className="flex-column-alc">
                  <img
                    src={item.img}
                    alt=""
                    style={{ width: "100px", marginBottom: "16px" }}
                  />
                  <div className="font-22 lh-30 c-desc fw-500">
                    {item.title}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex-jcb mt-40 mb-40">
            {processList.map((item, index) => {
              return (
                <div key={index} className="flex-alc">
                  <div className={styles.out_box}>
                    <div className={styles.inner_box}>{item}</div>
                  </div>
                  {index !== 5 && (
                    <img
                      src={require("../../assets/process-arrow.png")}
                      alt=""
                      className={styles.process_arrow}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex-alc-jcs">
            <div className={`${styles["solution-title"]}`}>从</div>
            <div
              className={`${styles["solution-title"]}`}
              style={{ color: "#0019FF" }}
            >
              到
            </div>
          </div>
          <div className="flex-alc-jcs mb-20">
            <div
              className={`${styles["transfer-box"]} ${styles["before-box"]}`}
            >
              <div
                className={`${styles["transfer-item"]} ${styles["before-item"]}`}
              >
                <CaretDownOutlined
                  style={{
                    color: "rgba(119, 123, 147, 0.8)",
                    fontSize: "20px",
                  }}
                />
                <div
                  className={`${styles["tranfer-text"]}`}
                  style={{ marginTop: "40px", lineHeight: "40px" }}
                >
                  局部人工优化、
                  <br />
                  事后复盘的模糊经营、
                  <br />
                  管理滞后、
                  <br />
                  依赖时候复盘的模糊经营
                </div>
              </div>
            </div>
            <img
              src={require("../../assets/transfer.png")}
              alt=""
              className={styles.transfer_img}
            />
            <div className={`${styles["transfer-box"]} ${styles["after-box"]}`}>
              <div
                className={`${styles["transfer-item"]} ${styles["after-item"]}`}
                style={{ height: "122px" }}
              >
                <CaretDownOutlined
                  style={{
                    color: "#fff",
                    fontSize: "20px",
                  }}
                />
                <div className={`${styles["tranfer-text"]}`}>
                  全局智能优化、实时监控、事前管理的数字化经营。
                </div>
              </div>
              <div
                className={`${styles["transfer-item"]} ${styles["after-item"]}`}
                style={{ height: "148px" }}
              >
                <CaretDownOutlined
                  style={{
                    color: "#fff",
                    fontSize: "20px",
                  }}
                />
                <div className={`${styles["tranfer-text"]}`}>
                  01. 行业know-how和技术know-how并重 02.
                  基于客户业务、技术和组织实际情况出发 03.
                  授之以渔，实现客户数字化能力内化
                </div>
              </div>
            </div>
          </div>
          <div
            className="font-16 lh-30 c-light"
            style={{ paddingBottom: "186px" }}
          >
            <span className="c-normal fwb">全流程护航：</span>
            从技术+数据+业务全方位诊断，设计共创数字化经营方案，产出详细设计，到系统平台建设落地实施，全流程端到端护航，拒绝纸上谈兵。
          </div>
        </div>
        <img
          src={require("../../assets/produce-bg.png")}
          alt=""
          className={styles.core_bg}
          style={{ top: "-40px" }}
        />
      </div>
    </div>
  );
};
export default SupplyChain;
