import React, { useState } from "react";
import "./index.css";
import BlockComponent from "../../components/blockComponent";
import ModuleHead from "../../components/moduleHead";
import FlexCard from "../../components/flexCard";
import PageHeadImage from "../../components/pageHeadImage";
// const baseUrl = "../../assets/";
const field_list = [
  {
    label: "库存积压与断货并存",
    key: "stock",
    describe:
      "部分商品库存积压，占用大量库位；主力商品却频频缺货，白白损失销售机会",
  },
  {
    label: "货盘失控",
    key: "uncontrol",
    describe: "商品囤积越来越多，毫无规划和章法，货盘失控",
  },
  {
    label: "价格管理混乱",
    key: "price",
    describe: "渠道多、定价不规范、价盘管理混乱",
  },
];
const process_list = [
  { label: "可视化视图", key: "vision" },
  { label: "指标联动与下钻", key: "kpi" },
  { label: "元数据管理", key: "data" },
  { label: "指标库自定义", key: "costomized" },
];
const card_list1 = [
  {
    title: "降低缺货率",
    detail: "通过系统智能调控有效降低缺货率",
    image: "experience-1",
  },
  {
    title: "保证履约率",
    detail: "通过系统智能调控有效降低缺货率",
    image: "experience-2",
  },
];
const card_list2 = [
  { detail: "可视化监控", image: "operate-1" },
  { detail: "任务式驱动", image: "operate-2" },
  { detail: "智能预警", image: "operate-3" },
  { detail: "决策建议", image: "operate-4" },
];
const card_list3 = [
  { detail: "提升管理效率", image: "operate-5" },
  { detail: "减少疏忽错漏", image: "operate-6" },
  { detail: "降低管控门槛", image: "operate-7" },
];
const card_list4 = [
  { detail: "降低人力成本", image: "price-1" },
  { detail: "加快周转率", image: "price-2" },
  { detail: "降低库存成本", image: "price-3" },
];
const Product = () => {
  const [active, setActive] = useState([false, false, false]);
  const changeColor = (index, hover) => {
    const _active = JSON.parse(JSON.stringify(active));
    _active.forEach((item, idx) => {
      _active[idx] = idx === index ? hover : false;
    });
    console.log(_active);
    setActive(_active);
  };
  return (
    <div className="product">
      <PageHeadImage
        title="供应链智能控制台"
        imgName="index-2"
        subtitle="一站式合作伙伴协同套件"
        content="解决传统 供应链工具低效的设计思路，从用户自动驱动+工具被动辅助
            的工作模式，转变为从用户厂家动线出发，通过工具推动用户工作流程
            自动化、智能化、高效化升级"
      />
      <div className="product-content bg-gradient-blue">
        <div className="main-area">
          <ModuleHead title="行业困扰" />
          <div className="flex-alc-jcs mt-56">
            {field_list.map((item, index) => (
              <div
                className="field-item"
                key={item.key}
                onMouseEnter={() => changeColor(index, true)}
                onMouseLeave={() => changeColor(index, false)}
              >
                <div
                  className={`mask ${
                    active[index] ? "orange-mask" : "blue-mask"
                  }`}
                ></div>
                <img
                  src={require(`../../assets/product-service-${index + 1}.png`)}
                  alt=""
                  className="field-img"
                />
                <div className="field-content">
                  <div className="label">{item.label}</div>
                  <div className="detail font-16">{item.describe}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="product-content white-bg">
        <div className="main-area">
          <ModuleHead title="产品解决方案介绍" />
          <div className="subtitle mt-56">供应链控制台设计思路</div>
          <img
            src={require("../../assets/product-1.png")}
            alt=""
            className="product-img mt-40"
          />
        </div>
      </div>
      <div className="product-content">
        <div className="main-area">
          <div className="subtitle">从局部的流程设计到 整体的场景设计</div>
          <img
            src={require("../../assets/product-2.png")}
            alt=""
            className="product-img mt-40"
          />
        </div>
      </div>
      <div className="product-content white-bg">
        <div className="main-area">
          <div className="subtitle">数据可视化</div>
          <div className="process flex-alc-jcs">
            {process_list.map((item, index) => (
              <div className="process-item font-20" key={item.key}>
                <img
                  src={require(`../../assets/product_icon_${index + 1}.png`)}
                  alt=""
                  className="process-img"
                />
                <span>{item.label}</span>
              </div>
            ))}
          </div>
          <img
            src={require("../../assets/product-3.png")}
            alt=""
            className="product-img mt-40"
          />
          <img
            src={require("../../assets/product-4.png")}
            alt=""
            className="product-img"
          />
        </div>
      </div>
      <BlockComponent
        backgroundClass="white-bg pb-50"
        title="核心优势"
        subtitle="基于来伊份供应链实践经验"
        detail={`从来伊份数字化转型吸取养分，融合了来伊份在供应链\n领域多年探索、升级、改造而凝练形成的方法论和实践\n经验，帮助中小零售企业规避企业发展过程中必然遇到\n的一些管理问题，少走弯路。`}
        imageName="module-lyf"
      />
      <BlockComponent
        backgroundClass="pt-50 pb-50"
        subtitle="完善的供应链计划方法与仿真应用"
        detail={`来伊份自主探索的沉淀的供应链计划方法论体系，结合机器\n学习，运筹优化等先进的技术手段，极大提升预测仿真效果，\n为去库存水位控制提供准确、高效的执行建议，阻止缺货与\n滞销并存的问题。`}
        imageName="p-2"
        Reverse
      />
      <BlockComponent
        backgroundClass="white-bg pb-50"
        subtitle="多业务模式灵活适配"
        detail={`来伊份智能供应链控制台考虑了不同企业商业模式和业\n务模式的差别，为客户提供了多环节、多维度的灵活配\n置项，最大化满足企业个性化的管理需求，保证供应商\n管理的可能性。`}
        imageName="p-3"
      />
      <BlockComponent
        backgroundClass="pt-50 pb-50"
        subtitle="从计划、执行到监控、反馈的全链路闭环"
        detail={`来伊份自智能供应链控制台以数据驱动为原则，通过计\n划>执行>监控>定位>处理>反馈工作流引导库存服务标\n准化和闭环管理、实现数据驱动、指标先行、系统指导\n业务`}
        imageName="p-4"
        Reverse
      />
      <BlockComponent
        backgroundClass="white-bg pb-50"
        subtitle="低门槛设计、丰富的细节关怀"
        detail={`来伊份智能供应链控制台在设计之初就本着”以人为本“\n的思想，服务好使用系统的”人“，因此在系统的交互设\n计、可视化视觉呈现以及诸多细节上投入颇多，着重提\n升使用者的使用感受，降低使用门槛，提增工作效率，\n让用户更容易上手。例如关键配置项都有相应指导说明\n，不仅告知配置的目的还举例给到选择建议。`}
        imageName="p-5"
      />
      <div className="white-bg plr-10 pb-133">
        <div className="main-area">
          <ModuleHead title="我们可以为您带来" />
          <div className="subtitle mt-56 mb-40">用户体验提升</div>
          <FlexCard
            cardList={card_list1}
            size="large"
            contentStyle={{
              width: "572px",
              background:
                "linear-gradient(129.8deg, rgba(241,248,255,1) 0%, rgba(218,236,254,1) 100%)",
            }}
          />
          <div className="subtitle mt-64 mb-40">运营管理能力提升</div>
          <FlexCard
            cardList={card_list2}
            size="small"
            column
            contentStyle={{
              flexDirection: "column-reverse",
              width: "274px",
              background:
                "linear-gradient(180deg, rgba(244,249,255,1) 0%, rgba(255,255,255,0) 100%)",
            }}
          />
          <FlexCard
            cardList={card_list3}
            size="small"
            column
            contentStyle={{
              flexDirection: "column-reverse",
              width: "374px",
              background:
                "linear-gradient(180deg, rgba(244,249,255,1) 0%, rgba(255,255,255,0) 100%)",
            }}
          />
          <div className="subtitle mt-56 mb-40">成本降低</div>
          <FlexCard
            cardList={card_list4}
            size="small"
            contentStyle={{
              width: "374px",
              background:
                "linear-gradient(90deg, rgba(244,249,255,1) 0%, rgba(255,255,255,0) 100%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Product;
