import React, { useState } from "react";
import "./index.css";

const ContactUs = () => {
  const [showMethod, setShowMethod] = useState({
    phone: false,
    message: false,
  });
  const showCard = (type, show) => {
    const obj = JSON.parse(JSON.stringify(showMethod));
    obj[type] = show;
    setShowMethod(obj);
  };
  return (
    <div className="contact">
      {showMethod.phone && (
        <div className="pop-card">
          <div className="font-12 fw-500 phone">联系电话</div>
          <div className="font-16 fw-700">021 - 50672081</div>
        </div>
      )}
      {showMethod.message && (
        <div className="pop-card bottom-card">
          <div className="font-12 fw-500 phone">合作留言</div>
          <div className="font-16 fw-700">提交您的宝贵留言</div>
        </div>
      )}

      <div className="contact-us font-16 flex-column-alc">
        <div
          className="contact-method flex-column-alc-jcc"
          onMouseEnter={() => showCard("phone", true)}
          onMouseLeave={() => showCard("phone", false)}
        >
          <img
            src={require(`../../assets/${
              showMethod.phone
                ? "icon-phone-telephone-active"
                : "icon-phone-telephone"
            }.png`)}
            alt=""
            className="contact-img"
          />
          <span
            className={`font-12 fw-500 ${showMethod.phone ? "hover-font" : ""}`}
          >
            电话咨询
          </span>
        </div>
        <div className="divide"></div>
        <div
          className="contact-method flex-column-alc-jcc"
          onMouseEnter={() => showCard("message", true)}
          onMouseLeave={() => showCard("message", false)}
        >
          <img
            src={require(`../../assets/${
              showMethod.message ? "icon-comments-active" : "icon-comments"
            }.png`)}
            alt=""
            className="contact-img"
          />
          <span
            className={`font-12 fw-500 ${
              showMethod.message ? "hover-font" : ""
            }`}
          >
            合作留言
          </span>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
