import React from "react";
import styles from "./styles.module.css";
import PageHeadImage from "../../../components/pageHeadImage";
import BackToTop from "../../../components/backToTop";

import {
  ClockCircleOutlined,
  // Loading3QuartersOutlined,
} from "@ant-design/icons";
// import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

const NewsList = () => {
  const navigate = useNavigate();
  const news_list = [
    {
      id: "3",
      title: "第一财经采访 | 智慧供应链全面提升零售企业效率",
      time: "2022-10-18",
      image: "news4",
      content:
        "第一财经采访来伊份，来伊份科技高级产品总监介绍了来伊份智慧供应链系统，关于效率，机器一定胜于人脑。",
    },
    {
      id: "1",
      title: "来伊份斩获2021年IDC未来智能领军者卓越奖",
      time: "2022-01-14",
      image: "news1",
      content:
        "上海来伊份股份有限公司凭借“鲲鹏供应链数字化”项目，一举斩获由全球领先的第三方信息服务机构IDC主办的2021年第六届IDC中国数字化转型年度盛典“未来智能领军者-卓越奖”",
    },
    {
      id: "2",
      title: "对话丨斩获IDC大奖的背后，来伊份“数字化供应链”创新带来哪些惊喜",
      time: "2022-01-14",
      image: "news2",
      content:
        "DC中国副总裁兼首席分析师武连峰作客来伊份与来伊份集团副总裁兼科技总裁周晨君首度展开深度对话，煮酒论英雄。",
    },
  ];
  const goDetail = (item) => {
    navigate("/newsDetail", { state: { ...item, news_list: news_list } });
  };
  // const spinNode = (
  //   <div className="spin-style font-14 flex-alc-jcc">
  //     <Loading3QuartersOutlined spin />
  //     <span>加载中...</span>
  //   </div>
  // );
  return (
    <div className={styles.news}>
      <BackToTop />
      <PageHeadImage
        title="新闻中心"
        content="了解来伊点科技动态，了解零售前沿科技资讯"
        imgName="index-4"
      />
      <div className={`plr-10 pt-64 ${styles["bg-list"]}`}>
        <div className="main-area white-bg">
          {news_list &&
            news_list.map((item, index) => (
              <div key={index} onClick={() => goDetail(item)}>
                <div className={`${styles["new-item"]} flex-alc-jcs`}>
                  <div
                    className={styles["left-box"]}
                    style={{ width: `${item.image ? 664 : 924}px` }}
                  >
                    <div
                      className={`over-clamp-1 font-16 fw-700 ${styles["news-title"]}`}
                    >
                      {item.title}
                    </div>
                    <div
                      className={`over-clamp-2 font-14 fw-500 ${styles["news-content-list"]}`}
                    >
                      {item.content}
                    </div>
                    <div className={`flex-alc font-16 ${styles["time-box"]}`}>
                      <ClockCircleOutlined />
                      <span style={{ marginLeft: "4px" }}>{item.time}</span>
                    </div>
                  </div>
                  {item.image && (
                    <img
                      src={require(`../../../assets/${item.image}.png`)}
                      alt=""
                      className={styles["right-img"]}
                    />
                  )}
                </div>
                {index !== news_list.length - 1 && (
                  <div className={styles.divider}></div>
                )}
              </div>
            ))}
          {/* <Spin indicator={spinNode} /> */}
        </div>
      </div>
    </div>
  );
};
export default NewsList;
