import React from "react";
import "./index.css";
import { useLocation, NavLink } from "react-router-dom";
import { Breadcrumb } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

const NewsDetail = () => {
  const location = useLocation();
  const { title, time, id, news_list } = location.state;
  const content = (
    <div>
      {id === "1" && (
        <div style={{ textAlign: "center" }}>
          <img
            src={require("../../../assets/news1-1.jpg")}
            alt=""
            style={{ width: "60%", marginBottom: "24px" }}
          />
          <div style={{ marginBottom: "30px" }}>
            近期，上海来伊份股份有限公司（以下简称“来伊份”）凭借“鲲鹏供应链数字化”项目，一举斩获由全球领先的第三方信息服务机构IDC主办的2021年第六届IDC中国数字化转型年度盛典“未来智能领军者-卓越奖”。该奖项是最具权威性的数字化奖项之一，标志着来伊份数字化建设成果受到行业权威的认可，跻身具有“未来商业”、“未来运营”能力，并在行业中持续创新发展的领军企业圈。
          </div>
          <div style={{ marginBottom: "24px" }}>
            <div className="tit-1">
              数字化转型夯实品牌硬实力 供应链升级铸就品牌竞争力
            </div>
            <p className="txt-1">
              来伊份鲲鹏项目以大数据为驱动，以运筹优化的决策和基于机器学习的预测等为核心，通过多模式和深度重构零售全链路的结合，打造行业开放、链接、协同、赋能的生态体系零售能力。同时，在目标管理、品类规划、智能选品、全网销售预测、计划和调拨，库存健康、订单履约管理、服务组网、智能库存方面取得了优秀的试点效果。目前，项目已经推广实现了38万长尾商品系统智能补货，超3000个门店系统自动补货和调拨。实现了插拔式的云仓云配的销售和供应网络体系，和全渠道“一盘货”的管理。总体可节约人效10万人时/每年，库存周转提升40％，并进一步向行业目标极限值挺进。
              科技的赋能提升了来伊份的精细化管理能力，实现了供应链的整体能力从原有的传统路线到数字化的转型，彻底打破了原有流程式供应链系统的诸多弊端，为来伊份供应链体系达到休闲零食行业一流水平提供了坚实基础，也为来伊份应对激烈的市场竞争修炼了内功。
            </p>
            <div className="tit-1">
              携手亿景智联数智化选址，加速“万家灯火”战略落地
            </div>
            <p className="txt-1">
              来伊份对科技的加注，并非仅鲲鹏供应链数字化单一项目。线下渠道一直是来伊份的优势，自2017年启动“万家灯火”计划以来，来伊份一直稳步开设直营门店，布局加盟门店，深化线下渠道布局，目前线下门店数已经超过3000家。为了更精准选址，提升门店经营效率，来伊份携手专业从事位置智能领域的亿景智联，共同推进数智化选址。亿景智联基于选址模型，结合来伊份选址业务需求，构建了从区域网格生成，到锁定目标规划路段，最后针对潜在物业进行全维度选址的智能化流程。此次，亿景智联为来伊份打造的选址系统平台覆盖多重拓店渠道，包括社区街边，购物中心、大卖场、百货、商业街区以及地铁、火车站、飞机场等。
              新零售时代，数字化、智能化转型成为了企业强劲的增长动能，休闲零食行业亦是如此。此次数智化的应用，加速了来伊份“万家灯火”战略的精准落地和规模化扩张，让来伊份线下渠道优势凸显，助力其抢占线下万亿零食市场红利。
            </p>
            <div className="tit-1">
              与瑞祥科技强强联手，用科技赋能跨境电商业务
            </div>
            <p className="txt-1">
              近年来，全球一体化进程推进加快，各项利于跨境电商发展的政策纷纷落地，这些因素对于中国跨境电商进出口的发展起到了十分积极的作用。作为四进进博会的中国休闲零食行业领跑者，来伊份一直积极响应国家倡导的构建内外双循环发展格局，拥抱“走出去，引进来”的号召，布局全球供应链试点跨境电商业务。近日，更是与瑞祥科技集团强强联手，在渠道共建、市场营销、资源整合和数字经济布局等多方面达成深度战略合作意向，旨在为消费者提供更好的购物场景和便捷的消费体验。
              瑞祥科技是一家科技型互联网科技集团，以市场为导向，以“互联网+”为发展动力，在布局数字经济，深入建设和完善数字化服务体系等方面取得多项成就，并拥有以“瑞祥全球购”线下智慧新零售门店为交汇点的BBC运营生态圈。
              本次合作，来伊份将借力瑞祥科技，打造深度融合线下线上多渠道的智慧型新零售服务，为推动新零售发展、建设智慧消费生态赋能。来伊份正在布局旗下的全球购平台伊国际实现新消费的全渠道升级，用崭新的模型链接业务的各个触点及业态，把世界创新产品从原产地引进来，打消消费者对产品品质的担忧。同时，提供多品种的一站式购物平台，让消费者享受到“一键到家”的便捷购物服务。
              “智慧化零售是来伊份未来的发展方向。我们以科技为手段，坚持工匠精神做好产品，让更多人吃到中国美食，用实际行动带头擦亮中国食品的金字招牌”，来伊份联合创始人、董事长施永雷表示。
            </p>
          </div>
        </div>
      )}
      {id === "2" && (
        <div>
          <p style={{ textAlign: "center" }}>
            如今的来伊份究竟
            <br />
            是一家零售企业还是科技企业？
            <br /> “鲲鹏项目”
            <br />
            到底对来伊份未来的发展有什么支撑？ <br />
            与AI技术的结合，
            <br />
            对休闲食品行业意味着什么.? <br />
            未来几年，
            <br /> “来伊份”智慧供应链探路何方……
            <br />
            <br />
            数字焕颜 科技有型
            <br />
            2021年第六届IDC中国数字化转型年度盛典上
            <br />
            来伊份荣膺“未来智能领军者——卓越奖”
            <br />
            <br /> 借此机遇
            <br />
            IDC中国副总裁兼首席分析师武连峰
            <br />
            作客来伊份与
            <br />
            来伊份集团副总裁兼科技总裁周晨君 <br />
            首度展开深度对话，煮酒论英雄
            <br />
            <br />
            当“智慧零售”遇上“黑科技” 畅谈来伊份在数字化2.0时代下
            <br />
            如何实现供应链数字化创新。
          </p>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <video width="597" height="336" controls>
              <source
                src="https://lyd-bzh.obs.myhuaweicloud.com/assets/video/news-2.mp4"
                type="video/mp4"
              ></source>
            </video>
          </div>
        </div>
      )}
      {id === "3" && (
        <div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <video width="597" height="336" controls>
              <source
                src="https://rmrbcmsonline.peopleapp.com/upload/zw/bjh_video/1666070624df692894d16228c48a13311b36312223.mp4"
                type="video/mp4"
              ></source>
            </video>
          </div>
        </div>
      )}
      {/* {id !== "2" && id !== "1" && (
        <div>
          <img
            src={require("../../../assets/news3.png")}
            alt=""
            style={{ width: "100%", marginBottom: "24px" }}
          />
          <div style={{ marginBottom: "30px" }}>
            Cloud
            Studio是一款在线版的VsCode,无需本地安装vscode，浏览器上直接在线code,对于不想安装本地vsCode，如果你远程办公，如果你不想背着电脑回家，家里有一台能联网的机器，那么你不用远程控制公司电脑，你只需要登录cloud
            studio就可以在线编码，并实时保存代码，这是一种趋势，也是一种非常不错的体验，本文是一篇笔者关于Cloud
            Studio在线编程的入门使用体验。
          </div>
          <div style={{ marginBottom: "24px" }}>
            Cloud
            Studio是一款在线版的VsCode,无需本地安装vscode，浏览器上直接在线code,对于不想安装本地vsCode，如果你远程办公，如果你不想背着电脑回家，家里有一台能联网的机器，那么你不用远程控制公司电脑，你只需要登录cloud
            studio就可以在线编码，并实时保存代码，这是一种趋势，也是一种非常不错的体验，本文是一篇笔者关于Cloud
            Studio在线编程的入门使用体验。
          </div>

          <div>
            Cloud
            Studio是一款在线版的VsCode,无需本地安装vscode，浏览器上直接在线code,对于不想安装本地vsCode，如果你远程办公，如果你不想背着电脑回家，家里有一台能联网的机器，那么你不用远程控制公司电脑，你只需要登录cloud
            studio就可以在线编码，并实时保存代码，这是一种趋势，也是一种非常不错的体验，本文是一篇笔者关于Cloud
            Studio在线编程的入门使用体验。
          </div>
        </div>
      )} */}
    </div>
  );
  return (
    <div className="mt-100 plr-10">
      <div className="main-area">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <NavLink to="/newsList">新闻中心</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="white-bg mt-40 news-content">
          <div className="news-title fw-700">{title}</div>
          <div className="flex-alc font-16 fw-500 border-b pb-40">
            <ClockCircleOutlined />
            <span style={{ marginLeft: "4px" }}>{time}</span>
          </div>
          <div
            className="mt-24 font-14 border-b pb-24 content-text"
            dangeroussetinnerhtml={{ __html: content }}
          >
            {content}
          </div>
          <div className="pagination flex-alc-jcs font-14">
            <div className="page flex-alc">
              <div className="fw-500">上一篇：</div>
              <div className="page-title over-clamp-1">
                中国数字化转型服务商榜单榜单
              </div>
            </div>
            <div className="page flex-alc">
              <div className="fw-500">下一篇：</div>
              <div className="page-title over-clamp-1">
                中国数字化转型服务商榜单榜单
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsDetail;
