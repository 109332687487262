import React from "react";
import "./index.css";
import ModuleHead from "../moduleHead";

const BlockComponent = (props) => {
  const {
    backgroundClass,
    title,
    subtitle,
    detail,
    checkDetail,
    imageName,
    Reverse,
    pbottom,
    displayType = "column",
  } = props;

  return (
    <div
      className={`display-modules ${backgroundClass}`}
      style={{ paddingBottom: pbottom }}
    >
      {displayType === "column" && (
        <div className="main-area">
          {title && <ModuleHead title={title} line={false} />}
          {/* <div
          className={`module-detail flex-alc-jcs ${title ? "mt-56" : ""} ${
            Reverse ? "flex-reverse" : ""
          }`}
        > */}
          <div className="module-content">
            {/* <div className="label font-24">{subtitle}</div> */}
            <div className="describe font-16">{detail}</div>
            {/* {checkDetail && (
            <div className="check-detail flex-alc-jcs font-14">
              <span>查看详情</span>
              <img
                src={require("../../assets/icon-arrow-right.png")}
                alt=""
                className="arrow-right"
              />
            </div>
          )} */}
          </div>
          {imageName && (
            <img
              src={require(`../../assets/${imageName}.png`)}
              alt=""
              className="module-pic"
            />
          )}

          {/* </div> */}
        </div>
      )}
      {displayType === "row" && (
        <div
          className="main-area flex-alc-jcs"
          style={{ flexDirection: Reverse ? "row-reverse" : "row" }}
        >
          {imageName && (
            <img
              src={require(`../../assets/${imageName}.png`)}
              alt=""
              className="row-pic"
            />
          )}
          <div className="row-text">
            <div className="row-subtitle font-28 fwb mb-20">{subtitle}</div>
            <div className="describe-row font-16">{detail}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BlockComponent;
