import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import PageHeadImage from "../../components/pageHeadImage";
import ModuleHead from "../../components/moduleHead";
import TimelineCard from "../../components/timelineCard";
import BackToTop from "../../components/backToTop";
import ContactUs from "../../components/contactUs";
import { Timeline } from "antd";

const time_line = [
  {
    year: "2023年",
    name: "对外赋能元年",
    content: "价值供应链中台、数字化灯塔",
    activeColor: true,
    flag: true,
  },
  { year: "2022年", name: "数智化", content: "合作伙伴社区、来伊份大脑" },
  {
    year: "2021年",
    name: "智能化（AI）",
    content: "财务中台、MDM主数据平台、智能选址、云店",
    activeColor: true,
  },
  {
    year: "2020年",
    name: "产销协同",
    content: "供应链中台、全员销售、付费会员、直播巡店、网点管理",
  },
  {
    year: "2019年",
    name: "大中台",
    content: "业务中台、数据中台……",
  },
  {
    year: "2018年",
    name: "数字化转型",
    content: "智慧门店、精准化营销……",
  },
  {
    year: "2017年",
    name: "数字化营销",
    content: "全渠道预测补货、全渠道营销场景……",
  },
  {
    year: "2016年",
    name: "智慧化运营",
    activeColor: true,
    content: "门店空间陈列、CMS营运平台……",
  },
  {
    year: "2015年",
    name: "全渠道发展",
    content: "Hybris（电子商务平台）、Siebel 前后台……",
  },
  {
    year: "2014年",
    name: "加盟商协同",
    content: "供应商协同平台、TMS运输管理系统……",
  },
  {
    year: "2013年",
    name: "移动办公",
    content: "O2O、移动办公（MIP）……",
  },
  {
    year: "2012年",
    name: "移动互联网元年",
    content: "来伊份APP上线、POSDM……",
  },
  {
    year: "2011年",
    name: "无纸化办公",
    content: "T-BPM流程管理、BPC预算合并……",
  },
  {
    year: "2010年",
    name: "数字化管理",
    content: "HCM、BPM……",
  },
  {
    year: "2009年",
    name: "ERP系统建设",
    content: "SAP（一期）、BPM（一期）……",
  },
  {
    year: "2007年",
    name: "门店信息化",
    content: "门店POS系统、办公平台",
  },
  { year: "2004年", name: "基础建设", content: "协同管理软件" },
  {
    year: "2002年",
    name: "信息化",
    content: "店务管理系统",
    activeColor: true,
  },
  { year: "1999年", name: "元年", content: "门店进销存" },
];
const mapList = [
  { title: "17+", detail: "自主知识产权" },
  { title: "200+", detail: "产品研发人员" },
  { title: "上海+武汉", detail: "双研发中心" },
];
const AboutUs = () => {
  const [noEmpty, setNoEmpty] = useState(false);
  const resizeChange = () => {
    setNoEmpty(window.innerWidth <= 1024);
  };
  useEffect(() => {
    window.addEventListener("resize", resizeChange);
    return () => window.removeEventListener("resize", resizeChange);
  }, []);
  return (
    <div className="about-us">
      <BackToTop />
      <ContactUs />

      {/* <PageHeadImage
        title="来伊点科技有限公司"
        content="Shanghai LYDEEM Technology Co., Ltd"
        imgName="index-3"
      /> */}
      <div className="about_us_container">
        <img
          src={require("../../assets/index-3.png")}
          alt=""
          className="about_us_img"
        />
        <div
          className="about_us_card flex-alc-jcs"
          style={{ left: noEmpty ? "610px" : "50%" }}
        >
          <div className="card_block flex-column-alc-jcc">
            <div className="primary_font">200+</div>
            <div className="detail_font">产品研发人员</div>
          </div>
          <div className="card_block flex-column-alc-jcc">
            <div className="primary_font">17+</div>
            <div className="detail_font">自主知识产权</div>
          </div>
          <div className="card_block flex-column-alc-jcc">
            <div className="primary_font">2</div>
            <div className="detail_font">上海+武汉研发双中心</div>
          </div>
        </div>
      </div>
      <div
        className="plr-10 white-bg"
        style={{ paddingTop: "168px", paddingBottom: "150px" }}
      >
        <div className="main-area">
          <div className="relative" style={{ zIndex: 2 }}>
            <ModuleHead
              title="数字化转型实践者，助力企业高质量增长"
              line={false}
            />
            <div className="flex-alc-jcs" style={{ zIndex: 2 }}>
              <img
                src={require("../../assets/quotation1.png")}
                alt=""
                className="quotation"
              />
              <div style={{ color: "rgba(83, 83, 83, 1)" }}>
                来伊点科技是一家
                <span style={{ color: "rgba(0, 25, 255, 1)" }}>
                  综合数字化技术服务商
                </span>
                ，致力于为企业客户提供新一代数字化经营技术与服务
                <br />
                解决方案，围绕门店智能补货、渠道选品定价、仓配履约调度、库存水位管理、全链路数据可视
                <br />
                化、全渠道销售管理等场景，帮助企业解决在经营过程中面临的效率和成本问题，助力企业实现
                <br />
                数字化价值，创造新的增长动力。
              </div>
              <img
                src={require("../../assets/quotation2.png")}
                alt=""
                className="quotation"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="process-bg plr-10 white-bg">
        <div className="main-area">
          <div className="pt-100">
            <ModuleHead title="来伊点科技介绍" line={false} />
            <div className="font-16" style={{ color: "rgba(83, 83, 83, 1)" }}>
              <div className="tac">
                <span>孵化于来伊点科技的</span>
                <span style={{ color: "rgba(0, 42, 239, 1)" }}>
                  综合数字化技术服务商
                </span>
              </div>
              <div className="tac">
                致力于为企业客户提供新一代的数字化经营技术与服务解决方案
                <br />
                帮助企业实现数字化价值，创造新的增长动力
              </div>
            </div>
            <div className="flex-alc flex-jcb mt-40">
              <img
                src={require("../../assets/map.png")}
                alt=""
                style={{ width: "652px" }}
              />
              <div>
                {mapList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="map_module flex-column flex-alc flex-jcc"
                    >
                      <div className="map_title font-30 fwb">{item.title}</div>
                      <div className="map_detail font-20 fwb">
                        {item.detail}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}

      {/* <div className="line-box pb-100" style={{ paddingTop: "74px" }}>
            <ModuleHead title="来伊份数字化历程" />
            <Timeline mode="alternate" style={{ paddingTop: "90px" }}>
              {time_line.map((item, index) => (
                <Timeline.Item
                  key={index}
                  dot={
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        background: `${
                          item.activeColor ? "#FD6800" : "#0E60FF"
                        }`,
                      }}
                    ></div>
                  }
                >
                  <TimelineCard cardObj={item} />
                </Timeline.Item>
              ))}
            </Timeline>
          </div> */}
      {/* </div>
      </div> */}
    </div>
  );
};
export default AboutUs;
