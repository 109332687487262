import React from "react";
import "./index.css";

const FlexCard = (props) => {
  const { cardList, size, column, contentStyle } = props;
  return (
    <div className="flex-alc-jcs mb-24">
      {cardList &&
        cardList.map((item, index) => (
          <div
            className={`card ${
              column ? "flex-column-alc " : "flex-alc-jcs"
            } card-${size}`}
            style={contentStyle}
            key={index}
          >
            <div className="card-text">
              {item.title && <div className="title">{item.title}</div>}
              <div className="detail">{item.detail}</div>
            </div>
            {item.image && (
              <img
                src={require(`../../assets/${item.image}.png`)}
                alt=""
                className="card-img"
              />
            )}
          </div>
        ))}
    </div>
  );
};
export default FlexCard;
