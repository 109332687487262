import React from "react";
import { BackTop } from "antd";
import { UpOutlined } from "@ant-design/icons";
import "./index.css";

const BackToTop = () => {
  return (
    <BackTop>
      <div className="back-top flex-column-alc">
        <UpOutlined className="icon-top" />
        <div className="font-12 fw-500">回到顶部</div>
      </div>
    </BackTop>
  );
};
export default BackToTop;
