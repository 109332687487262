import PageHeadImage from "../../components/pageHeadImage";
import BlockComponent from "../../components/blockComponent";
import ModuleHead from "../../components/moduleHead";
import { CaretDownOutlined } from "@ant-design/icons";
import "./index.css";

const pointList = [
  {
    icon: require("../../assets/hand.png"),
    title: "合作伙伴难精细化管理",
    description:
      "信息化管理系统管理部分的合作伙伴管理业务，大部分业务线下进行，阻碍合作伙伴管理的精细化。",
  },
  {
    icon: require("../../assets/car.png"),
    title: "缺少采购过程管理",
    description:
      "ERP仅有采购业务的关键结果，缺乏过程数据，无法确认采购的决策是否诚信合规，难以监控合同履约情况。",
  },
  {
    icon: require("../../assets/house.png"),
    title: "集团集采管控缺位",
    description:
      "子公司各自为战，缺乏统一管控，招标与合同，合同与订单的断点导致集采价格和配额无法准确体现，成本协同无从下手。",
  },
  {
    icon: require("../../assets/cooperate.png"),
    title: "合作伙伴缺乏协同",
    description:
      "缺乏预测协同，紧急订单太多；业务不透明，收货对账付款都得催；重复提交目录，手工接收订单，效率低下。",
  },
];
const beforeList = [
  {
    text: "通过人工管理合作伙伴档案，线下确认资质，手动统计和维护合作伙伴的数据，无法进行合作风险的确认和评估。",
  },
  {
    text: "通过邮件发出询价单和获得报价单，线下或在审批系统中报批结果，手工更新ERP价格，创建合同，线下签署。",
  },
  {
    text: (
      <div>
        1、缺少需求预测计划，无法有效的传递变更；
        <br />
        2、手工传真、电话或邮件发布采购订单并进行订单确认；
        <br />
        3、人工传递收货计划，无法及时传递变更，供方确认不及时；
        <br />
        4、线下传递对账单，供应商自行开票，发票人工核对验证，费时不准确。
      </div>
    ),
  },
  {
    text: "线下沟通传递采购环节，执行效率低下，无法准确的共享执行过程中的变更节点。",
  },
];

const afterList = [
  {
    text: "建立差异化的管理流程，对接专业企业信息调查用具 ，快速获取供应商舆情信息，灵活配置供应商考评体系，满足各类评估需求。",
  },
  {
    text: "在线询比价，自动比对历史价格，自动形成授标审核，自动更新ERP价格，自动生成合同无需邮寄在线完成签署。",
  },
  {
    text: (
      <div>
        1、在线传递需求预测计划，提升上下游效率;
        <br />
        2、自动发布订单后供应商在线确认。跟踪执行进度并记录过程；
        <br />
        3、系统获取平台的交货计划及变更，自动传递给供应商进行确认；
        <br />
        4、基于收货业务对账省时准确，供应商导出对账结果开票确认，方便快捷。
      </div>
    ),
  },
  {
    text: "围绕采购业务流程，实现各角色、多维度信息深度互通，通过协同平台进行信息交换，最大程度降低供应链采购各环节信息不对称",
  },
];
const toolList1 = [
  {
    img: require("../../assets/value1.png"),
    title: "规范准入，降低风险",
    detail:
      "明确供应商的准入流程以及标准，规范供应商的信息维护，建立起供应商的分级分类体系。",
  },
  {
    img: require("../../assets/value2.png"),
    title: "规范准入，降低风险",
    detail:
      "寻源价格锁定，员工采购唯一渠道预算实时计算，提醒采购执行情况线上审批，便捷高效。",
  },
  {
    img: require("../../assets/value3.png"),
    title: "提高效率，支持业务",
    detail:
      "便捷的申请体验，快速的审批流程，促使供应商在线协同的PR→PO周期，平均降低80。",
  },
];
const toolList2 = [
  {
    img: require("../../assets/value4.png"),
    title: "信息共享，增强协同",
    detail:
      "全面提升商机、计划、订单、收货、对账等协同效率，通过平台进行信息交换，大程度降低供应链采购各环节信息不对称。",
  },
  {
    img: require("../../assets/value5.png"),
    title: "推动采购部门职能转换",
    detail:
      "从采购执行高占比转变到供应商管理、价格管理的高占比，提升供方管理水平和业务效率，推进企业采购部门职能转换。",
  },
  {
    img: require("../../assets/value6.png"),
    title: "数据透明，风控合规",
    detail:
      "确保贸易合规，所有支出一目了然，便于分析订单可跟踪，三单自动匹配业务合规内嵌，杜绝体外操作，风控大数据监控。",
  },
];
const Partner = (props) => {
  return (
    <div className="partner-content">
      <PageHeadImage
        title="合作伙伴协同平台"
        content="不仅是采购协同，而是对外部合作伙伴的整体协同门户"
        imgName="produce_supply_banner"
      />
      <div
        className="plr-10 white-bg"
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        <div className="main-area">
          <ModuleHead title="企业发展痛点" line={false} />
          <div className="flex-jcb mt-40">
            {pointList.map((item, index) => {
              return (
                <div key={index} className="point-card">
                  <img src={item.icon} alt="" className="card-icon" />
                  <div className="card-title">{item.title}</div>
                  <div className="card-desc">{item.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="plr-10 white-bg">
        <div className="main-area">
          <ModuleHead title="解决方案" line={false} />
          <div className="flex-alc-jcs mt-30">
            <div className="solution-title">Before</div>
            <div className="solution-title" style={{ color: "#0019FF" }}>
              After
            </div>
          </div>
          <div className="flex-alc-jcs pb-100">
            <div className="transfer-box before-box">
              {beforeList.map((item, index) => {
                return (
                  <div key={index} className="transfer-item before-item">
                    <CaretDownOutlined
                      style={{
                        color: "rgba(119, 123, 147, 0.8)",
                        fontSize: "20px",
                      }}
                    />
                    <div className="tranfer-text">{item.text}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ height: "778px" }}>
              <div className="relative mt-10">
                <img
                  src={require("../../assets/transfer.png")}
                  alt=""
                  className="transfer-img"
                />
                <div className="absolute c-primary font-16 fwb arrow-text">
                  引入协同
                </div>
              </div>
              <div className="relative">
                <img
                  src={require("../../assets/transfer.png")}
                  alt=""
                  className="transfer-img"
                />
                <div className="absolute c-primary font-16 fwb arrow-text">
                  寻源协同
                </div>
              </div>
              <div className="relative" style={{ marginTop: "60px" }}>
                <img
                  src={require("../../assets/transfer.png")}
                  alt=""
                  className="transfer-img"
                />
                <div className="absolute c-primary font-16 fwb arrow-text">
                  采购协同
                </div>
              </div>
              <div className="relative" style={{ marginTop: "60px" }}>
                <img
                  src={require("../../assets/transfer.png")}
                  alt=""
                  className="transfer-img"
                />
                <div className="absolute c-primary font-16 fwb arrow-text">
                  信息共享
                </div>
              </div>
            </div>

            <div className="transfer-box after-box">
              {afterList.map((item, index) => {
                return (
                  <div key={index} className="transfer-item after-item">
                    <CaretDownOutlined
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    />
                    <div className="tranfer-text">{item.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="relative" style={{ height: "446px" }}>
        <div className="plr-10 white-bg">
          <ModuleHead title="亮点功能" line={false} />
          <div className="main-area">
            <div className="step-number">01</div>
          </div>
        </div>
        <div className="fix-block" style={{ top: "140px" }}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="partner1"
            pbottom="20px"
            subtitle="合作伙伴全生命周期管理"
            detail={
              <div>
                <span className="fwb c-title">完整的引入流程：</span>
                供应商从注册开始，在平台经过一系列审核认证后，最终成为合格供应商；
                <br />
                <span className="fwb c-title">严格的供应商准入评估：</span>
                支持多级审核、实地评鉴、物料认证等，清晰掌握供应商实际情况，通过质量对供应商把关完善的供应；
                <br />
                <span className="fwb c-title">完善的供应商绩效考核：</span>
                对供应商质量、成本、交货、服务等进行考核，不合格的要求整改。
              </div>
            }
          />
        </div>
      </div>
      <div className="relative" style={{ height: "373px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">02</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="partner2"
            pbottom="20px"
            Reverse={true}
            subtitle="科学的合作伙伴绩效管理方法论"
            detail={`灵活配置的供应商考评体系，满足各类供应商评估需求，促进供应商的改进与发展。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "360px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number">03</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="partner3"
            pbottom="16px"
            subtitle="寻源全流程管理"
            detail={`多样化的寻源方式，包括招投标、竞价等，满足企业不同的寻源需求。在部分场景可以实时查看价格信息、排名信息，也可联系企业负责人线上谈判。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "310px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">04</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="partner4"
            pbottom="16px"
            Reverse={true}
            subtitle="采购订单全生命周期管理"
            detail={`基于订单内容创建送货单，格式统一，快捷方便；待收货看板，采购方可提前获知供应商送货计划，便于接收准备；基于交货日期可以控制供应商交货时间，以优化库存管理。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "310px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number">05</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="partner5"
            pbottom="16px"
            subtitle="合作伙伴基于收货数据自主对账"
            detail={`基于入库物料凭证对账，保证数量准确；企业与合作伙伴双方对对账单数据确认无误后，企业向合作伙伴发起开票申请，合作伙伴对开票申请进行确认。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "610px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">06</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="partner6"
            pbottom="100px"
            Reverse={true}
            subtitle="高效的业务信息协同"
            detail={`增强协同效率，全面提升计划协同、订单协同、对账协同等协同效率。通过协同平台进行信息交换，最大程度降低供应链采购各环节信息的不对称。`}
          />
        </div>
      </div>
      <div className="white-bg">
        <div className="plr-10 produce-bg">
          <div className="main-area">
            <ModuleHead title="应用价值" line={false} />
            <div className="mt-50">
              <div className="flex-alc-jcs border-bottom">
                {toolList1.map((item, index) => {
                  return (
                    <div key={index} className="tac tool-box">
                      <img
                        src={item.img}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                      <div className="font-22 mt-16">{item.title}</div>
                      <div
                        className="font-16 c-desc mt-8"
                        style={{ lineHeight: "30px", textAlign: "left" }}
                      >
                        {item.detail}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex-alc-jcs">
                {toolList2.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="tac tool-box"
                      style={{ paddingTop: "45px", paddingBottom: "16px" }}
                    >
                      <img
                        src={item.img}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                      <div className="font-22 mt-16">{item.title}</div>
                      <div
                        className="font-16 c-desc mt-8"
                        style={{ lineHeight: "30px", textAlign: "left" }}
                      >
                        {item.detail}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Partner;
