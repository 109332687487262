import PageHeadImage from "../../components/pageHeadImage";
import BlockComponent from "../../components/blockComponent";
import ModuleHead from "../../components/moduleHead";
import { CaretDownOutlined } from "@ant-design/icons";
import "./index.css";

const pointList = [
  {
    icon: require("../../assets/shortage.png"),
    title: "事前：定价难",
    description:
      "定价过低，无法覆盖经营成本，导致净利润率偏低；定价过高，超过了目标用户的承受范围，导致商品没有竞争力。",
  },
  {
    icon: require("../../assets/monitor.png"),
    title: "事中：难以监控破价行为",
    description:
      "没有系统对营销价格进行实时监控，无法了解商品是否发生破价，破了多少，破到什么价格层级。",
  },
  {
    icon: require("../../assets/computer.png"),
    title: "事后：无法监控异常订单",
    description:
      "对于破价和低毛利的订单，没有系统的数据报表进行统计，无法对异常订单进行记录和复盘。",
  },
];
const beforeList = [
  {
    text: "企业管理者不知道该如何合理定价，往往是根据经验，在采购成本价上再加价的形式产生销售价格。",
  },
  {
    text: "企业做营销活动时，商品优惠价格定多少，什么优惠活动不能做，缺乏数据支撑，优惠活动会不会损伤毛利只有事后看财报能发现",
  },
  {
    text: "营销活动后，对于低于成本价和毛利润过低的订单，没有专门的报表记录，无法进行复盘和总结。",
  },
];

const afterList = [
  {
    text: "系统综合考虑在途、已下单未在途库存、采购提前期、箱规等各⽅⾯因素计算准确的补货量，每天滚动计算。",
  },
  {
    text: "多渠道价盘系统让商品的成本和毛利清晰可见，同时支持破价管控，将损伤毛利的优惠活动识别并拦截，避免事后发觉。",
  },
  {
    text: "多渠道价盘系统能够实时监控价盘的执行情况，交易过程中会对订单打标，对于异常订单形成专门监控报表方便客户复盘和总结。",
  },
];
const toolList1 = [
  { img: require("../../assets/tool1.png"), title: "合理的商品定价模型" },
  { img: require("../../assets/tool2.png"), title: "对渠道价格实时监控" },
  { img: require("../../assets/tool3.png"), title: "异常价格预警" },
];
const toolList2 = [
  { img: require("../../assets/tool4.png"), title: "多层管控减少出错" },
  { img: require("../../assets/tool5.png"), title: "提升价格管理效率" },
  { img: require("../../assets/tool6.png"), title: "降低管控门槛" },
];
const MultiplePrice = (props) => {
  return (
    <div className="price-content">
      <PageHeadImage
        title="多渠道价盘管理系统"
        content="精准的价格管理体系，实现价格看得见、管得住、用得好"
        imgName="produce_supply_banner"
      />
      <div
        className="plr-10 white-bg"
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        <div className="main-area">
          <ModuleHead title="企业发展痛点" line={false} />
          <div className="flex-jcb mt-40">
            {pointList.map((item, index) => {
              return (
                <div key={index} className="pain-card">
                  <img src={item.icon} alt="" className="card-icon" />
                  <div className="card-title">{item.title}</div>
                  <div className="card-desc">{item.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="plr-10 white-bg">
        <div className="main-area">
          <ModuleHead title="解决方案" line={false} />
          <div className="flex-alc-jcs mt-30">
            <div className="solution-title">Before</div>
            <div className="solution-title" style={{ color: "#0019FF" }}>
              After
            </div>
          </div>
          <div className="flex-alc-jcs pb-100">
            <div className="transfer-box before-box">
              {beforeList.map((item, index) => {
                return (
                  <div key={index} className="transfer-item before-item">
                    <CaretDownOutlined
                      style={{
                        color: "rgba(119, 123, 147, 0.8)",
                        fontSize: "20px",
                      }}
                    />
                    <div className="tranfer-text">{item.text}</div>
                  </div>
                );
              })}
            </div>
            <img
              src={require("../../assets/transfer.png")}
              alt=""
              className="transfer-img"
            />
            <div className="transfer-box after-box">
              {afterList.map((item, index) => {
                return (
                  <div key={index} className="transfer-item after-item">
                    <CaretDownOutlined
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    />
                    <div className="tranfer-text">{item.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="plr-10 white-bg" style={{ paddingBottom: "88px" }}>
        <div className="main-area">
          <ModuleHead title="产品理念" line={false} />
          <img
            src={require("../../assets/product-theory.png")}
            alt=""
            style={{ width: "100%", marginTop: "14px" }}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "390px" }}>
        <div className="plr-10 white-bg">
          <ModuleHead title="亮点功能" line={false} />
          <div className="main-area">
            <div className="step-number">01</div>
          </div>
        </div>
        <div className="fix-block" style={{ top: "140px" }}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="price1"
            pbottom="17px"
            subtitle={
              <div>
                价格线配置：
                <br />
                满足多级价格个性化计算方式需求
              </div>
            }
            detail={`通过自定义价格线的层级、价格线的名称和每个价格线的计算方式，同时满足不同客户对不同商品多层级价格个性化计算方式的复杂场景。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "418px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">02</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="price2"
            pbottom="17px"
            Reverse={true}
            subtitle={
              <div>
                目标利润管理：
                <br />
                根据渠道设定目标利润
              </div>
            }
            detail={`根据渠道预留利润，设置商品在每个价格线的目标利润，商品最终的零售价格充分考虑商品出厂过程中各个参与方，充分保证合作伙伴的利润。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "430px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number">03</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="price3"
            pbottom="29px"
            subtitle={
              <div>
                价格方案：
                <br />
                多种方案，满足各种价格制定场
              </div>
            }
            detail={`价盘系统同时支持根据不同渠道和地区制定不同的价格方案，能够覆盖线上和线下销售的大部分场景。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "370px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">04</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="price4"
            pbottom="17px"
            Reverse={true}
            subtitle={
              <div>
                商品价盘管理：
                <br />
                精准控制商品价格，防止价格错乱
              </div>
            }
            detail={`价盘系统对于经过计算后会生成价格预览，方便客户掌握每个商品的多层级价格，针对每个预览的应用需要经过发布和审批，防止误操作导致的价格错乱。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "730px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number">05</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="price5"
            pbottom="111px"
            subtitle={
              <div>
                结构化价盘柱子：
                <br />
                商品价格中各种费用占比一目了然
              </div>
            }
            detail={`价盘系统中查看商品的价格详情，可以清晰地看到商品价格的具体构成，商品的价格层级，渠道运营费用、营销费用和利润等各个花费在价盘中清晰可见。`}
          />
        </div>
      </div>
      <div className="white-bg">
        <div className="plr-10 produce-bg">
          <div className="main-area">
            <ModuleHead title="应用价值" line={false} />
            <div
              className="mt-40 flex-wrap flex-jcb"
              style={{ paddingBottom: "80px" }}
            >
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>基于成本加成的商品定价模型</div>
              </div>
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>全价值链定价在线化管理</div>
              </div>
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>事前、事中、事后全链路交易价格管控</div>
              </div>
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>助力公司精细化运营</div>
              </div>
            </div>
            <ModuleHead title="我们可以为您带来" line={false} />
            <div className="mt-50">
              <div className="flex-alc-jcs border-bottom">
                {toolList1.map((item, index) => {
                  return (
                    <div key={index} className="tac tool-box">
                      <img
                        src={item.img}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                      <div className="font-22 mt-16">{item.title}</div>
                    </div>
                  );
                })}
              </div>
              <div className="flex-alc-jcs">
                {toolList2.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="tac tool-box"
                      style={{ paddingTop: "45px", paddingBottom: "16px" }}
                    >
                      <img
                        src={item.img}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                      <div className="font-22 mt-16">{item.title}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MultiplePrice;
