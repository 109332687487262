import { Layout, Popover } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import ProductMenu from "../../components/productMenu";
const { Header, Content, Footer } = Layout;

const nav_list = [
  { label: "产品及方案", key: "product" },
  { label: "新闻中心", key: "newsList" },
  { label: "关于我们", key: "aboutUs" },
];
const ReactLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentNav, setCurrentNav] = useState("index");
  const [navBg, setNavBg] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    console.log(location.pathname);
    let key = location.pathname.split("/")[1];
    if (
      [
        "produceSupply",
        "multiplePrice",
        "lighthouse",
        "partner",
        "supplyChain",
      ].includes(key)
    ) {
      key = "product";
    }
    setCurrentNav(key);
  }, []);

  const changePage = (e, item) => {
    console.log(item, location, "currentNav");
    setCurrentNav(item.key);
    if (item.key === "product") {
      setShowMenu(true);
      e.stopPropagation();
      return;
    }
    window.scrollTo(0, 0);
    setShowMenu(false);
    navigate(item.key);
  };
  const hoverMenu = (e, item) => {
    setCurrentNav(item.key);
    if (item.key === "product") {
      setShowMenu(true);
      e.stopPropagation();
      return;
    }
  };

  const changeNav = (childData) => {
    setShowMenu(false);
  };
  const changeNavBg = () => {
    window.scrollY >= 40 ? setNavBg(true) : setNavBg(false);
  };

  window.addEventListener("scroll", changeNavBg);
  return (
    <Layout className="layout">
      <Header
        className="header"
        style={{ background: navBg ? "#fff" : "rgb(255, 255, 255, 0.1)" }}
      >
        <div className="flex-alc-jcs main-area relative">
          <div className="logo flex-alc-jcs">
            <img
              src={require("../../assets/logo.png")}
              alt=""
              className="logo-icon-head"
            />
            {/* <span className="logo-text">来伊点科技</span> */}
          </div>
          <div className="nav flex-alc-jcs">
            {[{ label: "首页", key: "index" }, ...nav_list].map((item) => (
              <div
                key={item.key}
                className={
                  currentNav === item.key ? "nav-item nav-selected" : "nav-item"
                }
                onMouseMove={(e) => hoverMenu(e, item)}
                onClick={(e) => changePage(e, item)}
              >
                {item.label}
              </div>
            ))}
          </div>
          {showMenu && (
            <div
              style={{ position: "absolute", left: "600px", top: "56px" }}
              onMouseEnter={() => {
                setShowMenu(true);
              }}
              onMouseLeave={() => setShowMenu(false)}
            >
              <ProductMenu changeNav={changeNav} />
            </div>
          )}
        </div>
      </Header>
      <Content className="content">
        <Outlet />
      </Content>
      <Footer className="footer">
        <div className="main-area flex footer-area">
          <div className="left">
            <img
              src={require("../../assets/logo2.png")}
              alt=""
              className="logo-icon"
            />

            <div className="flex-alc">
              <div className="addition">
                <div className="sign-text font-14 flex-alc mt-46">
                  <img
                    src={require("../../assets/location_icon.png")}
                    alt=""
                    className="icon-style"
                  />
                  <div className="ml-14 lh-18">
                    上海市松江区九亭街道沪松公路1399弄68号来伊份青年大厦8楼
                  </div>
                </div>
                <div className="sign-text font-14 flex-alc mt-48">
                  <img
                    src={require("../../assets/email_icon.png")}
                    alt=""
                    className="icon-style"
                  />
                  <div className="ml-14 lh-24">contact@laiyidian.com</div>
                </div>
              </div>
              <div
                className="sign-text flex-column flex-alc mt-28"
                style={{ marginRight: "18px" }}
              >
                <div
                  className="sign-text lh-16"
                  style={{ marginBottom: "6px" }}
                >
                  扫码关注
                </div>
                <img
                  src={require("../../assets/office_code.png")}
                  alt=""
                  style={{ width: "100px" }}
                />
                <div className="mt-8 lh-16">来伊点科技公众号</div>
              </div>
              <div className="sign-text flex-column flex-alc mt-28">
                <div
                  className="sign-text lh-16"
                  style={{ marginBottom: "6px" }}
                >
                  添加客服获取
                </div>
                <img
                  src={require("../../assets/customer_code.png")}
                  alt=""
                  style={{ width: "100px" }}
                />
                <div className="mt-8 lh-16">《来伊份数字化转型之路》</div>
              </div>
            </div>
          </div>
          <div className="divider-line"></div>
          <div className="middle">
            <div>
              <img
                src={require("../../assets/data-master.png")}
                alt=""
                className="master-style"
              />
            </div>
            <div
              className="flex-alc mb-22 pointer"
              onClick={(e) => changePage(e, { key: "produceSupply" })}
            >
              <img
                src={require("../../assets/product_bottom_1.png")}
                alt=""
                className="plat-icon"
              />
              <div className="ml-8 light-font">产供销协同平台</div>
            </div>
            <div
              className="flex-alc mb-22 pointer"
              onClick={(e) => changePage(e, { key: "lighthouse" })}
            >
              <img
                src={require("../../assets/product_bottom_2.png")}
                alt=""
                className="plat-icon"
              />
              <div className="ml-8 light-font">数字化经营灯塔</div>
            </div>
            <div
              className="flex-alc mb-22 pointer"
              onClick={(e) => changePage(e, { key: "multiplePrice" })}
            >
              <img
                src={require("../../assets/product_bottom_3.png")}
                alt=""
                className="plat-icon"
              />
              <div className="ml-8 light-font">多渠道价盘管理平台</div>
            </div>
            <div
              className="flex-alc pointer"
              onClick={(e) => changePage(e, { key: "partner" })}
            >
              <img
                src={require("../../assets/product_bottom_4.png")}
                alt=""
                className="plat-icon"
              />
              <div className="ml-8 light-font">合作伙伴协同平台</div>
            </div>
          </div>
          <div className="right">
            <div className="font-20 fwb" style={{ lineHeight: "28px" }}>
              解决方案
            </div>
            <div className="font-16 lh-24 mt-36 sign-text">
              <div
                className="pointer font-14"
                onClick={(e) => changePage(e, { key: "supplyChain" })}
              >
                价值供应链中台解决方案
              </div>
              <div className="mt-18 font-14">业务中台解决方案</div>
              <div className="mt-18 font-14">财务中台解决方案</div>
            </div>
          </div>
        </div>
        <div className="bottom sign-text flex-alc-jcc">
          ©2022 上海来伊点科技有限公司 沪ICP备20020234号-2
        </div>
      </Footer>
    </Layout>
  );
};
export default ReactLayout;
