import PageHeadImage from "../../components/pageHeadImage";
import BlockComponent from "../../components/blockComponent";
import ModuleHead from "../../components/moduleHead";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import "./index.css";

const pointList1 = [
  {
    icon: require("../../assets/manage.png"),
    title: "管理者看不到经营指标",
    description:
      "企业缺乏智能化生产管理工具，管理者看不到生产经营指标，无法指导企业的生产经营。",
  },
  {
    icon: require("../../assets/standard.png"),
    title: "缺少统一的指标衡量标准",
    description:
      "企业内部因为目标不同，缺少统一指标衡量标准，企业管理的效率低下。",
  },
  {
    icon: require("../../assets/coding.png"),
    title: "需要大量研发成本",
    description: "定制化报表需要大量研发成本，费用高昂，运营成本居高不下。",
  },
];
const pointList2 = [
  {
    icon: require("../../assets/coins.png"),
    title: "企业内部数据库不统一",
    description:
      "企业内部各个系统的数据库不同，无法放在一起分析比较，无法洞察产生问题的根本原因。",
  },
  {
    icon: require("../../assets/ability.png"),
    title: "分析处理能力严重不足",
    description:
      "Excel面对海量数据，分析能力无法迅速得到想要的指标和报表，不能快速的响应市场变化。",
  },
];
const beforeList = [
  {
    text: "企业管理者在经营过程中，不知道依靠什么类型的指标数据来提高生产经营水平。",
  },
  {
    text: "企业内部各部门目标不同，没有统一的指标管理，导致内部运行效率低下。",
  },
  {
    text: "企业管理者在经营过程中看不到经营数据，无法对生产经营活动作出及时有效的指导。",
  },
];

const afterList = [
  {
    text: "数字化经营平台基于多年行业经验，内置丰富的经营指标体系，让企业管理者无需复杂操作即可快速上手，轻松掌握企业所有关键经营数据，从而指导企业经营活动。",
  },
  {
    text: "数字化经营平台具有统一的指标管理目录，通过目标与指标的绑定、指标在组织内部拆解的方式，解决企业内部因为目标不一致运行效率低下的问题。",
  },
  {
    text: "数字化平台实时记录生产经营数据，并将产生的数据汇总综合分析，并支持各种个性化报表，为企业呈现丰富的表盘数据，生产经营数据一目了然。",
  },
];
const toolList1 = [
  { img: require("../../assets/model1.png"), title: "可视化监控" },
  { img: require("../../assets/model2.png"), title: "任务式驱动" },
  { img: require("../../assets/model3.png"), title: "智能预警" },
];
const toolList2 = [
  { img: require("../../assets/model4.png"), title: "减少疏忽错漏" },
  { img: require("../../assets/model5.png"), title: "提升管理效率" },
  { img: require("../../assets/model6.png"), title: "降低管理门槛" },
];
const Lighthouse = (props) => {
  return (
    <div className="lighthouse-content">
      <PageHeadImage
        title="数字化经营灯塔"
        content="不止于企业信息化工具更是智慧经营大脑"
        imgName="produce_supply_banner"
      />
      <div
        className="plr-10"
        style={{
          paddingTop: "90px",
          background: "#F1F6FF",
        }}
      >
        <div className="main-area relative" style={{ height: "440px" }}>
          <ModuleHead title="业务背景" line={false} />
          <img
            src={require("../../assets/field.png")}
            alt=""
            className="absolute"
            style={{ width: "426px", height: "440px", top: 0 }}
          />
          <div className="right-text">
            <div className="flex mb-22">
              <CaretRightOutlined
                style={{
                  color: "rgba(90, 176, 255, 1)",
                  fontSize: "20px",
                  marginRight: "14px",
                }}
              />
              <div className="right-font">
                面对外部市场环境的众多不确定性，企业需要修炼“内功”，将决策模式
                <span className="blue-color">从经验驱动转变为数据驱动</span>
              </div>
            </div>
            <div className="flex mb-22">
              <CaretRightOutlined
                style={{
                  color: "rgba(90, 176, 255, 1)",
                  fontSize: "20px",
                  marginRight: "14px",
                }}
              />
              <div className="right-font">
                数据驱动能帮助企业
                <span className="blue-color">
                  及时洞察市场变化并作出敏捷的响应
                </span>
                ，从而增强经营的韧性
              </div>
            </div>
            <div className="flex mb-22">
              <CaretRightOutlined
                style={{
                  color: "rgba(90, 176, 255, 1)",
                  fontSize: "20px",
                  marginRight: "14px",
                }}
              />
              <div className="right-font">
                在当前数字经济时代，企业需要结合现代管理科学理论和数字技术，
                <span className="blue-color">
                  构建以指标为核心的数字化经营管理体系
                </span>
              </div>
            </div>
            <div className="flex mb-22">
              <CaretRightOutlined
                style={{
                  color: "rgba(90, 176, 255, 1)",
                  fontSize: "20px",
                  marginRight: "14px",
                }}
              />
              <div className="right-font">
                指标既是企业管理的通用语言，也是承载数据价值的最小单元，
                <span className="blue-color">
                  指标将成为企业最核心、最重要的数据资产
                </span>
              </div>
            </div>
            <div className="flex">
              <CaretRightOutlined
                style={{
                  color: "rgba(90, 176, 255, 1)",
                  fontSize: "20px",
                  marginRight: "14px",
                }}
              />
              <div className="right-font">
                以指标管理为核心的数字化经营平台，已经成为企业
                <span className="blue-color">实现数据驱动的必要基础设施</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="plr-10 white-bg"
          style={{ paddingTop: "90px", paddingBottom: "100px" }}
        >
          <div className="main-area">
            <ModuleHead title="企业发展痛点" line={false} />
            <div className="flex-jcb mt-40">
              {pointList1.map((item, index) => {
                return (
                  <div key={index} className="pain-card">
                    <img src={item.icon} alt="" className="card-icon" />
                    <div className="card-title">{item.title}</div>
                    <div className="card-desc">{item.description}</div>
                  </div>
                );
              })}
            </div>
            <div className="flex-jcb" style={{ marginTop: "32px" }}>
              {pointList2.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="pain-card"
                    style={{ width: "486px" }}
                  >
                    <img src={item.icon} alt="" className="card-icon" />
                    <div className="card-title">{item.title}</div>
                    <div className="card-desc">{item.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="plr-10 white-bg">
        <div className="main-area">
          <ModuleHead title="解决方案" line={false} />
          <div className="flex-alc-jcs mt-30">
            <div className="solution-title">Before</div>
            <div className="solution-title" style={{ color: "#0019FF" }}>
              After
            </div>
          </div>
          <div className="flex-alc-jcs pb-100">
            <div className="transfer-box before-box">
              {beforeList.map((item, index) => {
                return (
                  <div key={index} className="transfer-item before-item">
                    <CaretDownOutlined
                      style={{
                        color: "rgba(119, 123, 147, 0.8)",
                        fontSize: "20px",
                      }}
                    />
                    <div className="tranfer-text" style={{ marginTop: "36px" }}>
                      {item.text}
                    </div>
                  </div>
                );
              })}
            </div>
            <img
              src={require("../../assets/transfer.png")}
              alt=""
              className="transfer-img"
            />
            <div className="transfer-box after-box">
              {afterList.map((item, index) => {
                return (
                  <div key={index} className="transfer-item after-item">
                    <CaretDownOutlined
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    />
                    <div className="tranfer-text">{item.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="relative" style={{ height: "305px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number">01</div>
          </div>
        </div>
        <div className="fix-block" style={{ top: "90px" }}>
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="lighthouse1"
            pbottom="17px"
            subtitle={
              <div>
                目标管理：
                <br />
                指标对齐目标，推动企业向前发展
              </div>
            }
            detail={`通过将目标数据关联到对应的指标，通过对过程指标和结果指标的检测和复盘，真正意义上实现了数据驱动决策和企业的增长。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "314px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">02</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="lighthouse2"
            pbottom="17px"
            Reverse={true}
            subtitle={
              <div>
                指标管理：
                <br />
                统一企业指标，提高数据洞察
              </div>
            }
            detail={`统一指标体系是企业高效管理和沟通的核心，数字化管理平台通过对企业指标的统一管理，形成标准的指标目录，使得企业能够根据需要定义和统一管理指标。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "487px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number">03</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="lighthouse3"
            pbottom="29px"
            subtitle={
              <div>
                仪表盘管理：
                <br />
                海量自定义仪表盘，便于监控复盘
              </div>
            }
            detail={`面对快迅速变化的业务需要，数字化管理平台拥有强大的多视角自定义仪表盘功能，通过对不同的指标和不同的统计维的自定义，实现丰富多样的指标报表。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "352px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">04</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="lighthouse4"
            pbottom="17px"
            Reverse={true}
            subtitle={
              <div>
                API集成：
                <br />
                促进协作办公和数据共享
              </div>
            }
            detail={`数字化经营平台拥有标准的api接口，支持用户根据需要定制工作流程，同时对接各种im工具，打通指标到业务执行闭环，大幅度提高企业运转效率。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "324px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number">05</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="lighthouse5"
            pbottom="17px"
            subtitle={
              <div>
                规范化指标管理：
                <br />
                严控指标生命周期，防止泛滥
              </div>
            }
            detail={`数字化经营平台同时具有规范化的指标管理体系，通过对指标的提出、评审、定义、录入、上线、分析使用环节的规范化管理，避免了指标一名多义和一义多名的现象。`}
          />
        </div>
      </div>
      <div className="relative" style={{ height: "396px" }}>
        <div className="plr-10 white-bg">
          <div className="main-area">
            <div className="step-number flex-jce">06</div>
          </div>
        </div>
        <div className="fix-block">
          <BlockComponent
            backgroundClass="white-bg"
            displayType="row"
            imageName="lighthouse6"
            pbottom="100px"
            Reverse={true}
            subtitle={
              <div>
                PDCA闭环管理：
                <br />
                场景覆盖全过程，驱动企业发展
              </div>
            }
            detail={`通过观察指标快速生成各类预警，同时还可以生成计划目标，将计划目标拆分后执行，最后通过指标数据对目标进行调整，形成良性的PDCA循环。`}
          />
        </div>
      </div>
      <div className="white-bg">
        <div className="plr-10 produce-bg">
          <div className="main-area">
            <ModuleHead title="应用价值" line={false} />
            <div
              className="mt-40 flex-wrap flex-jcb"
              style={{ paddingBottom: "80px" }}
            >
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>支撑以指标为核心、数据驱动的数字化经营体系</div>
              </div>
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>全价值链定价在线化管理</div>
              </div>
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>事前、事中、事后全链路交易价格管控</div>
              </div>
              <div className="slogen-style flex-alc">
                <div className="dot-style"></div>
                <div>助力公司精细化运营</div>
              </div>
            </div>
            <ModuleHead title="我们可以为您带来" line={false} />
            <div className="mt-50">
              <div className="flex-alc-jcs border-bottom">
                {toolList1.map((item, index) => {
                  return (
                    <div key={index} className="tac tool-box">
                      <img
                        src={item.img}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                      <div className="font-22 mt-16">{item.title}</div>
                    </div>
                  );
                })}
              </div>
              <div className="flex-alc-jcs">
                {toolList2.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="tac tool-box"
                      style={{ paddingTop: "45px", paddingBottom: "16px" }}
                    >
                      <img
                        src={item.img}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                      <div className="font-22 mt-16">{item.title}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Lighthouse;
