import React, { useEffect, useState, useRef } from "react";
import { Carousel, Button } from "antd";
import Slider from "react-slick";
import BlockComponent from "../../components/blockComponent";
import ModuleHead from "../../components/moduleHead";
import BackToTop from "../../components/backToTop";
import styles from "./styles.module.css";
import index1 from "../../assets/index-1.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, useLocation } from "react-router-dom";

const chain_list = [
  { label: "智能化业务决策", key: "decision" },
  { label: "一体化运营管理", key: "manage" },
  { label: "生态化价值合作", key: "cooperation" },
  { label: "敏捷化变革创新", key: "innovate" },
  { label: "面向未来", key: "future" },
];
const block_list = [
  { label: "客户第一", key: "custom" },
  { label: "合作共赢", key: "cooperation" },
  { label: "用心服务", key: "service" },
  { label: "真诚相待", key: "honest" },
];
const slide_list = [
  {
    title: "产供销协同",
    content:
      "通过需求计划，供应计划和执行协同三道防线，构建店仓智能供应网络，匹配店、仓智能补货工具，以更低的滞销、缺货及损耗，更低的成本显著提升库存周转率和服务水平，实现企业供应链侧的降本增效",
    img: require("../../assets/data-master-1.png"),
    path: "/produceSupply",
  },
  {
    title: "数字化经营灯塔",
    content:
      "构建于实时数据技术平台之上的数据驱动平台，实现实时、精细的数字化运营，构建可观察、可预警、可闭环的数字化管理模式，帮助企业破除经营迷雾，提升企业管理水平和效率",
    img: require("../../assets/data-master-2.png"),
    path: "/lighthouse",
  },
  {
    title: "多渠道价格管理平台",
    content:
      "为企业提供精准的价格管理体系，实现价格看得见--各渠道价格由系统辅助计算呈现、管得住---预算、政策管得住，不破价、用得好---通过价格管理倒逼成本结构和费用结构的优化",
    img: require("../../assets/data-master-3.png"),
    path: "/multiplePrice",
  },
  {
    title: "合作伙伴协同平台",
    content:
      "不仅仅是采购协同，而是对外部合作伙伴整体协同统一门户，实现合作伙伴全生命周期管理、资源共享、业务全面协同（从合作伙伴入驻、商务竞价到合同签订、绩效评估全体系全流程，是SRM的升级版）",
    img: require("../../assets/data-master-4.png"),
    path: "/partner",
  },
];
const WebIndex = () => {
  const [noEmpty, setNoEmpty] = useState(false);
  const [active, setActive] = useState(0);
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const SamplePrevArrow = (props) => {
    const { className, onClick, iconUrl } = props;
    return (
      <div className={className} style={{ width: "34px" }} onClick={onClick}>
        <img src={iconUrl} alt="" style={{ width: "34px" }} />
      </div>
    );
  };
  const settings = {
    className: styles["slide-style"],
    dots: true,
    arrows: true,
    centerPadding: "18%",
    // centerMode: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 1700,
        settings: {
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 1550,
        settings: {
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "0",
        },
      },
    ],
    customPaging: (i) => {
      return <div className={`${styles["custom-dot"]} custom-dot`}></div>;
    },
    afterChange: (i) => {
      console.log(i, "jump");
      setActive(i);
    },
    prevArrow: (
      <SamplePrevArrow iconUrl={require("../../assets/left-arrow.png")} />
    ),
    nextArrow: (
      <SamplePrevArrow iconUrl={require("../../assets/right-arrow.png")} />
    ),
  };
  console.log(sliderRef, "sliderRef");

  const resizeChange = () => {
    setNoEmpty(window.innerWidth <= 1024);
  };
  useEffect(() => {
    window.addEventListener("resize", resizeChange);
    return () => window.removeEventListener("resize", resizeChange);
  }, []);

  const changeActive = (index) => {
    setActive(index);
    sliderRef.current.slickGoTo(index);
    console.log(Slider, "click");
  };

  const goPage = (path_name) => {
    console.log(path_name, "path_name");
    navigate(path_name);
  };

  return (
    <div className={styles.index}>
      <BackToTop />
      {/* <Carousel
        autoplay
        className="carousel"
        dots={{ className: "carousel-dot" }}
      >
        <img src={index1} alt="" className="carousel-img" />
        <img src={index1} alt="" className="carousel-img" />
      </Carousel> */}
      <div className={styles.carousel}>
        <img src={index1} alt="" className={styles["carousel-img"]} />
      </div>
      <div className={styles["carousel-box"]}>
        <div className="main-area">
          <div className={styles["carousel-info"]}>
            <div className={`${styles["carousel-title"]} fw-700`}>
              来伊点科技
            </div>
            <div className="fw-500 font-22 lh-30 mb-20">
              数字化转型实践者，助力企业高质量增长。
            </div>
            <div className={`${styles["carousel-detail"]} font-16 c-desc`}>
              来伊点科技致力于为企业客户提供新一代的数字化经营技术与服务解决方案，帮助企业实现数字化价值，创造新的增长动力。
            </div>
            <div className="flex-alc">
              <div className={styles.index_tag}>#数字化转型</div>
              <div className={styles.index_tag}>#企业数字化解决方案</div>
              <div className={styles.index_tag}>#数据驱动经营</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.chain} style={{ left: noEmpty ? "610px" : "50%" }}>
        <div className={`${styles["chain-box"]} flex-alc-jcs`}>
          {chain_list.map((item, index) => (
            <div
              className={`${styles["chain-item"]} flex-alc font-14`}
              key={item.key}
            >
              <img
                src={require(`../../assets/index-head-${index + 1}.png`)}
                alt=""
                className={styles["item-icon"]}
              />
              <div className={styles["item-label"]}>{item.label}</div>
            </div>
          ))}
        </div>
      </div>
      <BlockComponent
        backgroundClass="white-bg"
        pbottom="120px"
        title="企业数字化转型咨询服务"
        detail={`来伊点科技拥有丰富的业务数据化、数据集成化、数据业务化经验，陪伴企业数字化转型全历程`}
        imageName="module-1"
      />
      <BlockComponent
        backgroundClass="white-bg"
        pbottom="120px"
        title="产品及解决方案一览"
        detail={
          <div>
            来伊点科技在企业数字化转型过程中沉淀大量的经验，推出了数字化经营大师系列产品
            <br />
            包含产供销协调平台、多渠道价盘管理平台、数字化经营灯塔和合作伙伴协同平台
          </div>
        }
        imageName="module-2"
      />
      <BlockComponent
        backgroundClass="white-bg"
        title="数字化经营大师系列"
        detail={`数字化经营大师是来伊点科技推出的助理产业升级、提升经营效率的系列产品`}
      />
      <div className="white-bg pt-30 plr-10">
        <div className={`${styles["tab-list"]} flex-alc-jcs font-24`}>
          {slide_list.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  active === index
                    ? `${styles["active-tab"]} ${styles["tab-item"]}`
                    : styles["tab-item"]
                }
                onClick={() => changeActive(index)}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </div>

      <div className="white-bg">
        <div className="main-area" style={{ width: "1024px" }}>
          <Slider {...settings} ref={sliderRef}>
            {slide_list.map((item, index) => {
              return (
                <div className={styles["slide-item"]} key={index}>
                  <img src={item.img} alt="" />
                  <div className={styles["item-content"]}>
                    <div className={`font-24 fwb ${styles["item-title"]}`}>
                      {item.title}
                    </div>
                    <div
                      className="font-14 mt-16 mb-30 c-desc"
                      style={{ lineHeight: "22px" }}
                    >
                      {item.content}
                    </div>
                    <Button
                      type="primary"
                      className={styles["btn-detail"]}
                      onClick={() => goPage(item.path)}
                    >
                      了解更多
                    </Button>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>

      <div className={`white-bg ${styles.service}`}>
        <div className="main-area">
          <ModuleHead title="服务理念" line={false} />
          <div className={`${styles["block-list"]} flex-alc-jcs mt-56`}>
            {block_list.map((item) => (
              <div className={styles["block-item"]} key={item.key}>
                <img
                  src={require(`../../assets/${item.key}.png`)}
                  alt=""
                  className={styles["block-icon"]}
                />
                <div className={`${styles.label} font-24 mt-30 fwb`}>
                  {item.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebIndex;
