import React from "react";
import "./index.css";

const ModuleHead = (props) => {
  const { title, line = true } = props;
  return (
    <div className="module-head">
      <div className="title">{title}</div>
      {line && <div className="bt-line"></div>}
    </div>
  );
};
export default ModuleHead;
